@import "../../index.scss";

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: clip;
  background-size: cover;
  position: relative;

  @include breakpoint("mobile") {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  &:not(:first-child) {
    border-top: black 0.5rem solid;
  }

  .content {
    display: flex;
    flex-direction: column;
    max-width: 40rem;
    align-items: center;
    gap: 2rem;
  }
}
