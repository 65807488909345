@import "index.scss";

.root {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  min-width: 1500px;
  min-height: 900px;
}

.map {
  transform-origin: top left;
  position: absolute;
  left: 50%;
  min-width: 960px;
  width: var(--width);
  height: var(--height);
  overflow: visible;
}
