@import "../../index.scss";

.modalPage {
  display: flex;
  padding-top: 15rem;

  @include breakpoint("mobile") {
    max-width: 330px;
  }

  .modalContents {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .card {
      display: flex;
      flex-direction: column;
      border-radius: 2rem;
      border: 0.4rem solid black;
      box-shadow: 0 0.3rem 0 0 rgb(0, 0, 0, 1);
      background: white;
      overflow: clip;
      width: 500px;
      height: min-content;
      @include breakpoint("mobile") {
        width: 320px;
      }

      .title {
        @extend .textOutline;
        --stroke-width: 4px;
        --stroke-color: black;
        font-size: 2rem;
        font-weight: 900;
        font-family: DonJoseBlack;
        text-transform: uppercase;
        color: white;
        white-space: nowrap;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0.5rem 2rem;

        @include breakpoint("mobile") {
          font-size: 1.5rem;
          --stroke-width: 3px;
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: left;
        padding: 3rem 2rem;
        border-top: 0.4rem solid black;
        @include breakpoint("mobile") {
          padding: 1.5rem 2rem;
        }
        .confirmationImg {
          position: relative;
          border: 0.4rem solid black;
          border-radius: 1rem;
          width: 200px;
          height: 200px;
          background-image: url("../../../public/assets/images/region/polarpeaks/rescue/capsuleGif.gif");
          background-size: cover;
          margin-bottom: 1.5rem;
          @include breakpoint("mobile") {
            width: 150px;
            height: 150px;
          }
        }
      }
    }
    @keyframes sparkly {
      0%,
      100% {
        opacity: 0.5;
        transform: rotate(180deg) scale(1);
      }
      50% {
        opacity: 1;
        transform: rotate(180deg) scale(1.1);
      }
    }

    .glimmer {
      position: absolute;
      top: -50px;
      width: 110%;
      height: 110%;
      background-image: url("../../../public/assets/images/region/polarpeaks/campsite/glimmer.png");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      animation: sparkly 2s ease-in-out infinite;

      @include breakpoint("mobile") {
        top: -250px;
        width: 150%;
        height: 150%;
      }
    }

    .imageViewerTitle {
      @extend .textOutline;
      --stroke-color: black;
      --stroke-width: 4px;
      color: white;
      font-family: DonJoseBlack;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2rem;
      font-weight: 600;
      padding-bottom: 0.5rem;
      padding-top: 5rem;
      z-index: 1;
      @include breakpoint("mobile") {
        font-size: 1.5rem;
        padding-top: 0rem;
        --stroke-width: 3px;
      }
    }

    .woofyCardWrapper {
      position: relative;
      display: flex;
      flex-direction: row;
      width: 100%;

      @include breakpoint("mobile") {
        flex-direction: column;
      }

      .woofyCard {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 400px;
        height: 461px;
        background-image: url("../../../public/assets/images/region/polarpeaks/campsite/picture_frame.png");
        background-size: 100% 100%;

        @include breakpoint("mobile") {
          width: 240px;
          height: 280px;
        }
        .woofyImageUnder {
          position: absolute;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 430px;
          height: 440px;
          background-image: url("../../../public/assets/images/region/polarpeaks/campsite/picture_frame2.png");
          background-size: 100% 100%;
          z-index: -1;

          @include breakpoint("mobile") {
            width: 260px;
            height: 260px;
          }
        }
        .woofyImage {
          display: flex;
          align-items: center;
          justify-content: center;
          aspect-ratio: 1 / 1;
          width: 325px;
          border: 4px black solid;
          border-radius: 0.5rem;
          margin-bottom: 1rem;

          @include breakpoint("mobile") {
            margin-bottom: 0.5rem;
            width: 180px;
          }
          img {
            height: 100%;
            width: 100%;
          }
        }
        .woofyId {
          @extend .textOutline;
          --stroke-color: black;
          --stroke-width: 3px;
          color: white;
          font-family: DonJoseBlack;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          font-size: 1.5rem;
          font-weight: 600;
          @include breakpoint("mobile") {
            font-size: 1.1rem;
            --stroke-width: 2px;
          }
        }
      }
      .button {
        img {
          width: 4rem;
        }
        &.left {
          transform: rotate(-90deg);
        }
        &.right {
          transform: rotate(90deg);
        }
        &.disabled {
          opacity: 0.4;
        }
      }
      .selectWrapper {
        align-self: center;
        position: relative;
        width: min-content;
        padding-left: 2rem;
        @include breakpoint("mobile") {
          padding-left: 0rem;
          padding-top: 1rem;
        }
      }
      .select {
        max-height: 400px;
        background-color: rgba(255, 255, 255, 0.3);
        padding: 0.6rem;
        border-radius: 0.5rem;
        overflow-y: scroll;
        position: relative;
        width: min-content;

        &::-webkit-scrollbar {
          display: none;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;

        @include breakpoint("mobile") {
          max-height: 200px;
        }
        .grid {
          .gridContainer {
            --grip-gap: 0.25rem;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: var(--grip-gap);
            grid-auto-flow: dense;
            @include breakpoint("mobile") {
              grid-template-columns: repeat(3, 1fr);
            }
            .cell {
              width: 70px;
            }
            .id {
              font-size: 0.7rem;
              width: calc(70px - 2 * 0.2rem);
            }
          }
        }
      }
    }
  }

  .modalButtons {
    padding-top: 20px;
  }
}

.grid {
  .gridContainer {
    --grip-gap: 0.25rem;

    display: grid;
    grid-template-columns: repeat(4, 1fr);

    @include breakpoint("desktop") {
      grid-template-columns: repeat(3, 1fr);
    }

    @include breakpoint("tablet") {
      grid-template-columns: repeat(2, 1fr);
    }

    grid-gap: var(--grip-gap);
    grid-auto-flow: dense;
  }

  .cell {
    position: relative;
    aspect-ratio: 1 / 1.2;
    width: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    color: white;

    &.empty {
      justify-content: center;
      .cellContainer {
        border: none;
        background: none;

        img {
          filter: opacity(0.3);
        }
      }
      .id {
        display: none;
      }
    }

    &.traveling {
      .cellContainer {
        filter: contrast(30%) brightness(150%);
      }
    }

    .helmet {
      display: none;
      position: absolute;
      padding: 0.5rem;

      top: 0;
      left: 0;
    }

    &.traveled {
      .helmet {
        display: inherit;
      }
    }

    &.selectable {
      cursor: pointer;

      &:hover {
        .cellContainer {
          padding: 0.2rem;
          border: 0.2rem black solid;
          scale: 1.1;
          z-index: 9999;
        }
      }
    }

    &.selected {
      .cellContainer {
        rotate: -10deg;
        border: 0.2rem black solid;
        padding: 0.2rem;
        z-index: 9999;
      }
    }
  }
}
.cell {
  position: relative;
  aspect-ratio: 1 / 1.2;
  width: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  color: white;
  .cellContainer {
    background: white;
    z-index: 999;
    position: relative;
    display: flex;
    border: 0.2rem black solid;

    img {
      height: 100%;
      width: 100%;
    }
  }
  .id {
    bottom: 0;
    position: absolute;
    color: black;
    z-index: 9999;
    border: 0.2rem black solid;
    background-color: white;
    width: calc(90px - 2 * 3px);
    font-size: 0.9rem;
    font-family: montserrat;
    font-weight: 500;
    text-align: center;
  }
}
