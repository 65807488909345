@import "../../index.scss";

.banner {
  position: relative;
  width: 450px;
  margin: 0 auto;
  @include breakpoint("mobile") {
    width: 300px;
  }
  .text {
    @extend .textOutline;
    --stroke-color: black;
    --stroke-width: var(--shadow-size, 6px);
    color: white;
    position: absolute;
    width: 350px;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    font-family: DonJoseBlack;
    font-size: var(--font-size, 2.5rem);
    text-align: center;
    text-transform: uppercase;
    line-height: 1;
    font-family: DonJoseBlack;
    @include breakpoint("mobile") {
      width: 250px;
      font-size: 1.5rem;
      --stroke-width: 3px;
    }
  }
}
