@import "../../index.scss";

.card {
  background: rgba(255, 255, 255, 1);
  border-radius: 2rem;
  border: 4px solid black;
  padding: 3rem 3rem 2rem 3rem;
  position: relative;
  z-index: 999;
  box-shadow: 0 0.4rem 0 0 rgba(0, 0, 0, 1);
  color: #2f2f2f;

  @include breakpoint("mobile") {
    padding: 1.5rem;
  }

  .title {
    font-size: 7.5rem;
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
    font-style: italic;
  }

  &.footer {
  }

  &.centerText {
    text-align: center;
  }

  .header {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    top: -29px;

    transform: translateY(calc(-100% + 6rem));
    @include breakpoint("mobile") {
      transform: translateY(-50%);
    }

    img {
      max-width: 80%;
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    transform: translateY(65%);

    @include breakpoint("mobile") {
      transform: translateY(85%);
    }
  }

  .block {
    padding: 1rem 0;

    &.small {
      font-size: 1.3rem;
      font-weight: 600;

      @include breakpoint("mobile") {
        font-size: 0.75rem;
      }
    }

    &.medium {
      font-size: 2rem;
      font-weight: 650;

      @include breakpoint("mobile") {
        font-size: 1.7rem;
      }
    }

    &.large {
      font-size: 2.5rem;
      font-weight: 800;

      @include breakpoint("mobile") {
        font-size: 1.25rem;
      }
    }

    &:not(:first-child) {
      padding: 1rem 0;
    }

    @include breakpoint("mobile") {
      &:not(:first-child) {
        padding-top: 0rem;
      }
    }

    img {
      border-radius: 2rem;
    }
  }
}

.bold {
  color: var(--color, #165cff);
  font-weight: 650;
}
