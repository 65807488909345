@import "../../index.scss";

.wrapper {
  position: relative;

  align-items: center;

  .container {
    position: absolute;
    display: flex;
    justify-content: center;
    z-index: 9999;
    top: 3rem;
    left: 0;
    right: 0;

    @include breakpoint("mobile") {
      top: 0;
    }
  }
}

.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;

  @include breakpoint("tablet") {
    display: block;
    align-items: flex-start;
    width: 100%;
  }

  .logo {
    @include breakpoint("tablet") {
      width: 3rem;
      height: 3rem;
      position: absolute;
      top: 3rem;
      left: 2rem;
      padding-top: 0;
    }

    padding-top: 0.5rem;
    img {
      max-height: 5rem;

      @include breakpoint("tablet") {
        max-width: 3.5rem;
      }
    }
  }

  .tabs {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    text-transform: uppercase;
    border: 4px solid black;
    border-radius: 2rem;
    box-shadow: 0 0.2rem 0 0 rgba(0, 0, 0, 1);
    background-color: #f86459;

    .tab:first-child {
      padding-left: 2rem;
    }

    .tab:last-child {
      padding-right: 2rem;
    }

    @include breakpoint("tablet") {
      display: inherit;
      flex-direction: column;
      border-radius: 0;
      align-items: flex-start;
      border: none;
      background-color: transparent;

      &.hide {
        display: none;
      }

      .tab:first-child {
        padding-left: 1.5rem;
      }

      .tab:last-child {
        padding-right: 1.5rem;
      }
    }

    .tab {
      border-radius: 2rem;
      cursor: default;
      position: relative;
      font-size: 1.9rem;
      color: white;
      padding: 0.4rem 1.5rem 0.4rem 1.5rem;
      font-family: DonJoseBlack;

      text-shadow: var(--text-shadow);
      a {
        padding: 0;
        color: inherit;
      }

      &:hover {
        .dropdown {
          display: block;
        }
        &::before {
          background-color: rgba(248, 100, 89, 0.45);
        }
      }

      &.active {
        background-color: white;
        color: #f86459;
        border: 4px solid black;
        margin: -4px;

        &::before {
          opacity: 0;
        }
      }

      @include breakpoint("tablet") {
        background-color: #ffffff;
        color: #60a3daff;
        border-radius: 0px;
        font-size: 1.7rem;
        --stroke-width: 3px;
        box-shadow: 0 0.3rem 0 0 rgba(0, 0, 0, 1);

        &:hover {
          border: none;
          background-color: #ffffff;
          color: #60a3daff;
          margin: 0px;
        }

        &.active {
          color: #e75b5b;
        }
      }

      &.hide {
        @include breakpoint("desktop") {
          display: none;
        }
      }

      .dropdownWrapper {
        position: absolute;
        left: -5%;
        bottom: 0;
        transform: translate(0%, 100%);
        padding: 0.4rem;
        background: transparent;

        @include breakpoint("tablet") {
          position: static;
          transform: translate(0, 0);
        }
      }

      .dropdown {
        background-color: #abb3c0;
        border-radius: 2rem;
        border: 4px solid black;
        box-shadow: 0 0.2rem 0 0 rgba(0, 0, 0, 1);
        text-align: left;
        display: none;

        @include breakpoint("tablet") {
          display: block;
          text-align: left;
          box-shadow: none;
          border: none;
          background-color: #ffffff;
        }

        .item {
          color: white;
          font-size: 1.8rem;
          padding: 0.6rem 1.5rem;
          white-space: nowrap;
          border-radius: 2rem;

          a {
            padding: 0;
          }

          &.active {
            color: #e75b5b;
          }

          &:hover {
            color: #abb3c0;
            background-color: white;
            border: 4px solid black;
            margin: -4px;
          }

          @include breakpoint("tablet") {
            color: #ababab;
            font-size: 1.6rem;
            padding: 0.2rem 1.5rem;

            &:hover {
              border: none;
              color: inherit;
              margin: 0px;
            }
          }
        }
      }
    }
  }

  .mobile {
    display: none;
    width: 6rem;
    height: 3rem;
    position: absolute;
    top: 2.5rem;
    right: 0;
    border: 4px solid black;
    border-right: 0px;
    border-radius: 2rem 0 0 2rem;
    box-shadow: 0 0.2rem 0 0 rgb(0, 0, 0);
    background-color: #f86459;

    .imageButton {
      width: 45px;
      position: relative;
      top: 8px;
      height: 32px;
      left: 16px;
      img {
        height: 32px;
      }
    }

    @include breakpoint("tablet") {
      display: block;
    }
  }

  .mobileTabs {
    background-color: white;
  }

  a {
    text-decoration: none;
  }
}
