@import "../../index.scss";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #c84c43;
  gap: 1.25rem;
  padding: 1rem 0;
  border-top: black 0.5rem solid;
  z-index: 999;

  * {
    font-size: 1rem;
    color: white;
    font-family: montserrat;
    font-weight: bolder;
    @include breakpoint("mobile") {
      font-size: 0.9rem;
    }
  }

  .socials {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    div {
      padding: 0 1rem;
    }
  }

  .contacts {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    div {
      padding: 0 1rem;
    }

    img {
      max-height: 3rem;
    }
  }

  .formWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0rem 1rem;
    @include breakpoint("mobile") {
      flex-direction: column;
    }

    .description {
      color: white;
      font-weight: 400;
      font-size: 0.9rem;
      font-family: montserrat;
      padding-right: 1rem;

      @include breakpoint("mobile") {
        font-size: 0.7rem;
        padding-bottom: 1rem;
      }
    }

    .form {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 350px;

      @include breakpoint("mobile") {
        height: 35px;
        width: 300px;
      }

      .emailField {
        position: relative;
        display: flex;
        align-items: center;
        width: 300px;
        height: 100%;
        background-color: white;
        border: 4px solid black;
        border-radius: 50px;
        z-index: 1;
        padding-left: 1rem;

        .text {
          font-family: montserrat;
          color: #abb3c0;
          background-color: white;
          font-weight: 400;
          font-size: 0.9rem;
          width: 200px;
          border: none;
          &:focus {
            outline: none;
          }
          @include breakpoint("mobile") {
            font-size: 0.7rem;
            width: 150px;
          }
        }
      }

      .button {
        @extend .textOutline;
        position: absolute;
        background-color: #c84c43;
        cursor: pointer;
        padding: 0.3rem 1rem;
        border: 4px solid black;
        border-radius: 1rem;
        color: white;
        user-select: none;
        --stroke-color: black;
        --stroke-width: 2.5px;
        font-family: DonJoseBlack;
        font-size: 1.25rem;
        z-index: 1;
        right: 0;

        &:hover {
          background-color: white;
          color: #c84c43;
        }
        @include breakpoint("mobile") {
          font-size: 1.05rem;
        }
      }
    }
  }
}
