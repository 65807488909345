@import "index.scss";

.mobileContainer {
  display: none;
  @include breakpoint("mobile") {
    display: inline-block;
  }
}

.topLogo {
  width: 60vw;
  margin-top: 120px;
  margin-bottom: 32px;
  margin-left: auto;
  margin-right: auto;
  img {
    width: 100%;
  }
}

.mobileSlideContainer {
  min-width: 100vw;
  position: relative;
  padding-top: 54px;

  &.cozyPenguinsSlide {
    overflow: hidden;
    width: 100vw;
    .slideImage {
      width: 120vw;
    }
  }
}

.slideImage {
  width: 100vw;
}

.mobileSlideRightInfo {
  position: absolute;
  height: 100%;
  right: 0px;
  top: -12px;
  width: 65vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &.waddleWarsInfo {
    top: -28px;
  }
}

.mobileSlideLeftInfo {
  position: absolute;
  height: 100%;
  left: 0px;
  top: -12px;
  width: 65vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.cozyPenguinsInfo {
    top: -32px;
  }

  &.cozyExplorersInfo {
    left: -10px;
  }

  &.campsiteInfo {
    left: -45px;
  }
}

.mobileSlideLogoContainer {
  width: 50%;
  display: flex;
  justify-content: center;
  img {
    width: 100%;
  }
}

.mobileSlideButtonsContainer {
  display: flex;
  margin-left: 8px;
  margin-top: 8px;
  img {
    max-width: 95%;
  }
}

.readNowButton,
.learnMoreButton,
.enterPortalButton {
  div {
    font-size: 22px !important;
    --stroke-width: 3px;
    white-space: nowrap;
  }
}
