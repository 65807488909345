@import "../../../../index.scss";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  min-height: 1000px;
  background-image: url("../../../../../public/assets/images/region/polarpeaks/campsite/bg.png");
  background-size: cover;
  background-position: center;
  overflow-x: scroll;
  overflow-y: hidden;

  @include breakpoint("mobile") {
    min-height: auto;
    height: min-content;
    flex-direction: row;
    align-items: center;
    padding-bottom: 5rem;
  }

  .contents {
    position: relative;
    width: 60%;
    padding: 10rem 6rem 2rem 6rem;
    height: calc(100% - (12rem));
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    z-index: 5;
    overflow-x: scroll;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: 1300px) {
      width: 80%;
    }

    @include breakpoint("mobile") {
      display: flex;
      min-width: auto;
      flex-direction: column;
      align-items: center;
      overflow: clip;
      padding: 6rem 6rem;
    }

    .logo {
      position: relative;
      width: 200px;

      @include breakpoint("mobile") {
        width: 150px;
      }
    }
    .centerContent {
      display: flex;
      flex-direction: column;
      align-items: center;

      .campsite {
        padding-top: 1rem;
        position: relative;
        width: 430px;

        @include breakpoint("desktop") {
          padding-top: 0rem;
          width: 360px;
        }
        @include breakpoint("mobile") {
          width: 250px;
        }
      }
      .button {
        margin-top: 20px;

        div {
          width: 100px;
          display: flex;
          justify-content: center;
          text-align: center;
          line-height: 0.8;
          font-size: 2.2rem;

          @include breakpoint("desktop") {
            line-height: 0.85;
            font-size: 1.7rem;
          }
        }
      }
      .smallButton {
        margin-top: 20px;
        div {
          width: 180px;
          display: flex;
          justify-content: center;
          text-align: center;
          line-height: 0.8;
          font-size: 1.7rem;

          @include breakpoint("desktop") {
            width: 150px;
            line-height: 0.85;
            font-size: 1.4rem;
          }
        }
      }
      .signsGrid {
        display: grid;
        gap: 0.5rem;

        .imageSign {
          position: relative;
          display: flex;
          align-items: center;
          width: 420px;
          cursor: pointer;
          background-size: contain;
          background-repeat: no-repeat;

          &.capsules {
            height: 200px;
            background-image: url("../../../../../public/assets/images/region/polarpeaks/campsite/capsule1.png");
            &:hover {
              background-image: url("../../../../../public/assets/images/region/polarpeaks/campsite/capsule0.png");
            }
            @include breakpoint("desktop") {
              height: 170px;
            }
            @include breakpoint("mobile") {
              height: 120px;
            }
          }
          &.woofys {
            height: 175px;
            background-image: url("../../../../../public/assets/images/region/polarpeaks/campsite/woofy1.png");
            &:hover {
              background-image: url("../../../../../public/assets/images/region/polarpeaks/campsite/woofy0.png");
            }
            @include breakpoint("desktop") {
              height: 145px;
            }
            @include breakpoint("mobile") {
              height: 105px;
            }
          }
          &.checker {
            height: 170px;
            background-image: url("../../../../../public/assets/images/region/polarpeaks/campsite/find1.png");
            &:hover {
              background-image: url("../../../../../public/assets/images/region/polarpeaks/campsite/find0.png");
            }
            @include breakpoint("desktop") {
              height: 140px;
            }
            @include breakpoint("mobile") {
              height: 100px;
            }
          }

          &:active:hover {
            top: 0.3rem;
          }
          &:hover {
            transition: scale 0.5s ease;
            scale: 1.05;
            .text {
              color: #317edc;
            }
          }

          @include breakpoint("desktop") {
            width: 350px;
          }
          @include breakpoint("mobile") {
            width: 250px;
          }

          .text {
            @extend .textOutline;
            position: absolute;
            width: 40%;
            --stroke-color: black;
            --stroke-width: 4px;
            color: white;
            padding: 0rem 4rem;
            font-family: DonJoseBlack;
            font-size: 1.8rem;
            text-align: center;
            text-transform: uppercase;
            line-height: 1;
            font-family: DonJoseBlack;

            @include breakpoint("desktop") {
              font-size: 1.5rem;
            }

            @include breakpoint("mobile") {
              padding: 0rem 2rem;
              width: 50%;
              font-size: 1.2rem;
              --stroke-width: 3px;
            }
          }
        }
      }
      .noItemsWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 300px;
        width: 370px;
        background-color: rgba(255, 255, 255, 0.5);
        padding: 0.6rem;
        border: 0.4rem black solid;
        border-radius: 0.5rem;
        position: relative;

        @include breakpoint("desktop") {
          height: 200px;
          width: 280px;
        }

        .noItemsText {
          font-family: montserrat;
          font-weight: 600;
          font-size: 1.3rem;
          text-align: center;
          line-height: 3rem;
          margin: 1.5rem;

          @include breakpoint("desktop") {
            font-size: 1rem;
            line-height: 2.7rem;
            margin: 0.5rem;
          }

          .bold {
            @extend .textOutline;
            --stroke-color: black;
            --stroke-width: 4px;
            font-family: DonJoseBlack;
            text-decoration: none;
            position: relative;
            font-size: 2rem;
            padding: 0rem 0.5rem;

            @include breakpoint("desktop") {
              font-size: 1.5rem;
              --stroke-width: 3px;
            }
          }

          .loadingDots {
            display: flex;
            justify-content: center;
            align-items: end;
          }

          .dot {
            width: 8px;
            height: 8px;
            background-color: white;
            border: 4px solid black;
            border-radius: 50%;
            margin: 8px 4px;
            opacity: 0;
            animation: loadingAnimation 1s infinite;
          }

          .dot:nth-child(1) {
            animation-delay: 0.2s;
          }

          .dot:nth-child(2) {
            animation-delay: 0.4s;
          }

          .dot:nth-child(3) {
            animation-delay: 0.6s;
          }
          .dot:nth-child(4) {
            animation-delay: 0.8s;
          }
          .dot:nth-child(5) {
            animation-delay: 1s;
          }

          @keyframes loadingAnimation {
            0%,
            100% {
              opacity: 0;
            }
            25% {
              opacity: 1;
            }
            50%,
            75% {
              opacity: 0;
            }
          }
        }
      }
    }
    .rightContent {
      position: relative;
      display: grid;
      grid-template-rows: 3fr 1fr;
      padding: 5.2rem 0rem;
      height: 60%;

      @include breakpoint("desktop") {
        padding-top: 3.3rem;
      }

      @include breakpoint("mobile") {
        gap: 0.5rem;
        grid-template-rows: none;
        grid-template-columns: 1fr 1fr;
        padding-top: 1rem;
        padding-bottom: 0rem;
      }

      .infoWrapper {
        justify-self: center;
        width: 200px;
        padding-right: 1rem;

        @include breakpoint("desktop") {
          width: 180px;
        }

        @include breakpoint("mobile") {
          width: 150px;

          align-self: end;
          padding-right: 0rem;
        }
      }
    }

    .textSign {
      position: relative;
      width: 250px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      align-self: end;
      justify-self: center;

      @include breakpoint("desktop") {
        width: 220px;
      }

      @include breakpoint("mobile") {
        width: 180px;
      }

      .sign {
        width: 100%;
      }

      .text {
        position: absolute;
        max-width: 70%;
        font-weight: 450;
        font-size: 0.9rem;
        font-family: montserrat;
        text-align: left;
        color: white;
        margin-top: 20%;
        padding: 0rem 3rem;

        @include breakpoint("desktop") {
          padding: 0rem 2.5rem;
          font-size: 0.8rem;
        }
        @include breakpoint("mobile") {
          padding: 0rem 1.5rem;
          font-size: 0.7rem;
        }
      }
    }
  }

  .snowbehind {
    bottom: 0;
    z-index: 0;
    width: 100%;

    @include breakpoint("desktop") {
      width: 150%;
    }
  }

  .snowfront {
    bottom: -5px;
    z-index: 3;
    width: 100%;

    @media (max-width: 1100px) {
      width: 150%;
    }
  }

  .right {
    width: 450px;
    bottom: -30px;
    right: 15rem;
    z-index: 1;
    transition:
      width 1s ease,
      right 1s ease;
    filter: FlipH;

    @media (max-width: 1550px) {
      right: 10rem;
      width: 350px;
    }

    @include breakpoint("tablet") {
      right: 4rem;
      width: 250px;
    }

    @include breakpoint("mobile") {
      width: 120px;
    }
  }
  .rightCapsule {
    width: 700px;
    bottom: -50px;
    right: -20rem;
    z-index: 1;
    transition:
      width 1s ease,
      right 1s ease;
    filter: FlipH;

    @media (max-width: 1550px) {
      width: 600px;
    }

    @include breakpoint("tablet") {
      right: -15rem;
      width: 400px;
    }

    @include breakpoint("mobile") {
      right: -5rem;
      width: 200px;
    }
  }
  .left {
    width: 700px;
    bottom: 10px;
    left: 0rem;
    z-index: 1;
    transition:
      width 1s ease,
      right 1s ease;
    filter: FlipH;

    @media (max-width: 1550px) {
      width: 500px;
    }

    @include breakpoint("tablet") {
      bottom: 0px;
      width: 400px;
    }

    @include breakpoint("mobile") {
      width: 200px;
    }
  }
}
.selectWrapper {
  position: relative;
  width: min-content;
}
.select {
  max-height: 300px;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 0.6rem;
  border: 0.4rem black solid;
  border-radius: 0.5rem;
  overflow-y: scroll;
  position: relative;
  width: min-content;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  @include breakpoint("desktop") {
    max-height: 300px;
  }
  @include breakpoint("mobile") {
    max-height: 200px;
  }
}
.modalSnow {
  position: fixed;
  width: 100%;
  bottom: -0.5rem;
  z-index: 10000;

  @media (max-width: 900px), (max-height: 500px) {
    width: 200%;
  }
}
