@import "../../../index.scss";

.hero {
  background-image: url("../../../../public/assets/images/games/cozy_verse/bg.png");
  background-size: cover;
  min-height: calc(max(100vh, 30rem));

  @media only screen and (max-height: 800px) {
    min-height: 770px;
  }
}

.about {
  padding: 5rem 0;
  background-image: url("../../../../public/assets/images/games/shared/tile.png");
  background-repeat: repeat;
  font-family: montserrat;
}

.appStoreMobileButtons {
  display: none;

  @include breakpoint("unity-min") {
    display: flex;
  }
}

.appStoreDesktopButtons {
  @include breakpoint("unity-min") {
    display: none;
  }
}
