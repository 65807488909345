@import "../../index.scss";

.root {
  z-index: 999;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  position: relative;
  background: rgba(255, 255, 255, 1);
  border-radius: 2rem;
  border: black solid 0.3rem;
  padding: 3.5rem;
  box-shadow: 0 0.3rem 0 0 rgba(0, 0, 0, 1);

  &.footer {
    padding-bottom: 4rem;

    @include breakpoint("mobile") {
      padding: 0rem;
      padding-bottom: 2rem;
      padding-top: 2rem;
    }
  }

  .title {
    @extend .textOutline;

    font-size: 7rem;
    text-align: center;
    text-transform: uppercase;
    color: white;
    line-height: 1;
    font-family: DonJoseBlack;

    text-shadow: var(--text-shadow-big);

    @include breakpoint("mobile") {
      font-size: 4rem;
    }
  }

  .subtitle {
    font-size: 1.1rem;
    font-family: montserrat;
    font-weight: 600;
    text-align: left;
    color: #000000;
    padding: 0rem 1rem 1rem 2rem;
  }

  .footer {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    transform: translateY(65%);

    @include breakpoint("mobile") {
      transform: translateY(75%);
    }
  }
}
