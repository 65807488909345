@import "index.scss";

.help {
  margin: 1rem;
  padding: 0.2rem 0.6rem;
  position: absolute;
  border-radius: 4rem;
  left: 0;
  bottom: 0;
  border: 4rem;
  display: none;
  background-color: #1a1411;
  opacity: 75%;
  z-index: 999;

  &.mobile {
    display: flex;
  }

  .body {
    display: flex;
    justify-items: center;
    align-items: center;
    gap: 0.5rem;

    .text {
      color: white;
      font-size: 1rem;
      margin: auto;

      @include breakpoint("mobile") {
        font-size: 0.7rem;
      }
    }

    img {
      max-height: 1.5rem;
      width: 1.5rem;
    }
  }

  @include breakpoint("unity-min") {
    display: flex;
  }
}

.play {
  @include breakpoint("unity-min") {
    display: none;
  }
}

.learnMore {
  display: none;
  max-width: 80%;

  @include breakpoint("unity-min") {
    display: inherit;
  }
}

.gameCard {
  position: relative;
  height: 540px;
  width: 960px;

  @include breakpoint("unity-min") {
    height: 100%;
    width: 100%;
    max-height: 100%;
  }

  @media only screen and (max-height: calc(540px + 18rem)) {
    height: 100%;
    width: 100%;
    max-height: 100%;
  }

  .bg {
    width: 100%;

    @include breakpoint("unity-min") {
      width: auto;
      height: 100%;
      display: none;
    }
  }

  .gameStarted {
    display: none;

    @include breakpoint("unity-min") {
      display: flex;
    }

    @media only screen and (max-height: calc(540px + 18rem)) {
      display: flex;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    justify-content: center;

    @include breakpoint("unity-min") {
      gap: 0.75rem;
    }

    .actions {
      display: flex;

      @include breakpoint("unity-min") {
        padding-top: 2rem;
      }
    }

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include breakpoint("unity-min") {
        padding-top: 3em;
        max-height: none;
      }

      .logo {
        max-height: 18rem;
        max-width: 60vw;

        &.small {
          max-height: 13rem;
        }

        &.verySmall {
          max-height: 10rem;
        }

        @include breakpoint("mobile") {
          max-width: 75vw;
        }
      }

      .subtitle {
        color: #ffffff;
        font-family: montserrat;
        font-weight: 600;
        font-size: 1.5rem;
        text-align: center;
        text-shadow: 1px 0 2px black;
        display: none;
        word-wrap: break-word;
        white-space: normal;

        &.preview {
          display: block;
        }

        @include breakpoint("unity-min") {
          display: block;
        }

        @include breakpoint("tablet") {
          font-size: 1.2rem;
        }

        @include breakpoint("mobile") {
          font-size: 1rem;
          max-width: 75%;
        }
      }
    }
  }

  .playButton {
    @extend .breathingButton;
    max-height: 30%;

    img {
      max-height: 100%;
    }

    @include breakpoint("unity-min") {
      display: none;
    }
  }

  .iconDown {
    @extend .breathingButton;
    width: 4.5rem;
    display: none;

    @include breakpoint("unity-min") {
      display: block;
    }
  }
}

.cozyLogo {
  float: left;
  width: 100%;
  position: absolute;
  z-index: 9999;

  img {
    width: 6rem;
    margin: 0.75rem 1rem;
  }

  @include breakpoint("mobile") {
    img {
      max-width: 4rem;
    }
  }
}

.hide {
  display: none;
}

.unityScreen {
  position: absolute;
  height: 540px;
  width: 960px;
  z-index: 20;

  @include breakpoint("unity-min") {
    display: none;
  }
}

.gameContainer {
  border: white 0.75rem solid;
  box-shadow: 0 10px 0 0 rgba(0, 0, 0, 1);
  height: 540px;
  width: 960px;
  z-index: 999;
  margin: 10rem;

  .placeholder {
    position: absolute;
    height: 540px;
    width: 960px;
    z-index: 10;

    @include breakpoint("unity-min") {
      display: none;
    }
  }

  @include breakpoint("unity-min") {
    max-height: 100%;
    border: none;
    box-shadow: none;
    margin: 1rem;
  }
}

.previewContainer {
  border-bottom: white 0.75rem solid;
  @include breakpoint("unity-min") {
    border: none;
  }
}

.description {
  font-size: 1.25rem;
  max-width: 960px;
  padding: 0 1rem;

  p {
    text-align: left;
  }

  h1,
  h2,
  h3,
  h4 {
    text-align: center;
  }

  ul {
    list-style-position: outside;
    list-style-type: "- ";
    padding: 0 0 0 1rem;
  }
}

.appStoreInfo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;

  @include breakpoint("tablet") {
    flex-direction: column;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .comingSoon {
      filter: grayscale(0.5);
      opacity: 0.4;
    }
  }
}

.about {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
  overflow-y: clip;
  border-top: black 0.5rem solid;
  gap: 5rem;
  padding: 5rem 0;

  @include breakpoint("mobile") {
    gap: 2rem;
    padding: 2rem 0;
  }

  * {
    z-index: 99;
  }

  .float {
    position: absolute;
    z-index: 1;

    @include breakpoint("mobile") {
      display: none;
    }
  }
}

.thumbnails {
  height: auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;

  img {
    width: 50%;
  }

  @include breakpoint("tablet") {
    padding: 1rem;

    img {
      width: 100%;
    }
  }
}

.thumbnailsPortrait {
  height: auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;

  img {
    width: 33%;
  }

  @include breakpoint("tablet") {
    padding: 1rem;

    img {
      width: 50%;
    }
  }
}

.fullScreen {
  position: relative;
  bottom: -25px;
  left: 928px;
  cursor: pointer;
  width: 45px;
  height: 45px;
  background: url("../../../public/assets/images/games/shared/buttons/btn_full.png")
    center/cover no-repeat;

  &:hover {
    background: url("../../../public/assets/images/games/shared/buttons/btn_full0.png")
      center/cover no-repeat;
  }

  @include breakpoint("unity-min") {
    display: none;
  }
}
