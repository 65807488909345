@import "../../../../index.scss";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  min-height: 1000px;
  background-image: url("../../../../../public/assets/images/region/polarpeaks/rescue/bg.png");
  background-size: cover;
  background-position: center;
  overflow-x: scroll;
  overflow-y: hidden;

  @include breakpoint("mobile") {
    min-height: auto;
    height: min-content;
    flex-direction: row;
    align-items: center;
    padding-bottom: 5rem;
  }

  .contents {
    position: relative;
    width: 60%;
    padding: 10rem 6rem 2rem 6rem;
    height: calc(100% - (12rem));
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem;
    z-index: 5;
    overflow-x: scroll;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: 1300px) {
      width: 80%;
    }

    @include breakpoint("mobile") {
      display: flex;
      min-width: auto;
      flex-direction: column;
      align-items: center;
      overflow: clip;
      padding: 6rem 6rem;
    }

    .bannerWrapper {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100px;
      @include breakpoint("mobile") {
        height: 70px;
      }
      .banner {
        position: absolute;
        width: 500px;
        @include breakpoint("desktop") {
          width: 400px;
        }
        @include breakpoint("mobile") {
          width: 300px;
        }
      }
    }
    .centerContent {
      display: flex;
      flex-direction: column;
      align-items: center;

      .capsule {
        position: relative;
        width: 400px;

        @include breakpoint("desktop") {
          width: 350px;
        }
        @include breakpoint("mobile") {
          width: 200px;
        }
      }
      .button {
        margin-top: 20px;

        div {
          width: 100px;
          display: flex;
          justify-content: center;
          text-align: center;
          line-height: 0.8;
          font-size: 2.2rem;

          @include breakpoint("desktop") {
            line-height: 0.85;
            font-size: 1.7rem;
          }
        }
      }
      .smallButton {
        margin-top: 20px;
        div {
          width: 180px;
          display: flex;
          justify-content: center;
          text-align: center;
          line-height: 0.8;
          font-size: 1.7rem;

          @include breakpoint("desktop") {
            width: 150px;
            line-height: 0.85;
            font-size: 1.4rem;
          }
        }
      }

      .signsGrid {
        padding-top: 2rem;
        display: grid;
        gap: 1rem;

        @include breakpoint("mobile") {
          padding-top: 1rem;
          gap: 0.5rem;
        }

        .imageSign {
          position: relative;
          display: flex;
          align-items: center;
          width: 450px;
          height: 170px;
          cursor: pointer;
          background-size: contain;
          background-repeat: no-repeat;

          &.ready {
            background-image: url("../../../../../public/assets/images/region/polarpeaks/rescue/cozy_1.png");
            &:hover {
              background-image: url("../../../../../public/assets/images/region/polarpeaks/rescue/cozy_0.png");
            }
          }
          &.rescued {
            background-image: url("../../../../../public/assets/images/region/polarpeaks/rescue/explorer_1.png");
            &:hover {
              background-image: url("../../../../../public/assets/images/region/polarpeaks/rescue/explorer_0.png");
            }
          }
          &.checker {
            background-image: url("../../../../../public/assets/images/region/polarpeaks/rescue/search_1.png");
            &:hover {
              background-image: url("../../../../../public/assets/images/region/polarpeaks/rescue/search_0.png");
            }
          }

          &:active:hover {
            top: 0.3rem;
          }
          &:hover {
            transition: scale 0.5s ease;
            scale: 1.05;
            .text {
              color: #317edc;
            }
          }

          @include breakpoint("desktop") {
            height: 120px;
            width: 350px;
          }
          @include breakpoint("mobile") {
            height: 90px;
            width: 250px;
          }

          .text {
            @extend .textOutline;
            position: absolute;
            width: 40%;
            --stroke-color: black;
            --stroke-width: 4px;
            color: white;
            padding: 0rem 4rem;
            font-family: DonJoseBlack;
            font-size: 1.8rem;
            text-align: center;
            text-transform: uppercase;
            line-height: 1;
            font-family: DonJoseBlack;

            @include breakpoint("desktop") {
              font-size: 1.5rem;
            }

            @include breakpoint("mobile") {
              padding: 0rem 2rem;
              width: 50%;
              font-size: 1.2rem;
              --stroke-width: 3px;
            }
          }
        }
      }
    }

    .rightContent {
      position: relative;
      display: grid;
      grid-template-rows: 3fr 1fr;
      padding: 6rem 0rem;
      height: 50%;

      @include breakpoint("desktop") {
        padding-top: 4rem;
      }

      @include breakpoint("mobile") {
        gap: 0.5rem;
        grid-template-rows: none;
        grid-template-columns: 1fr 1fr;
        padding-top: 0rem;
        padding-bottom: 0rem;
      }

      .infoWrapper {
        justify-self: center;
        width: 200px;
        padding-right: 1rem;

        @include breakpoint("desktop") {
          width: 180px;
        }

        @include breakpoint("mobile") {
          width: 150px;

          align-self: end;
          padding-right: 0rem;
        }
      }
    }

    .textSign {
      position: relative;
      width: 250px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      align-self: end;
      justify-self: center;

      @include breakpoint("desktop") {
        width: 220px;
      }

      @include breakpoint("mobile") {
        width: 180px;
      }

      .sign {
        width: 100%;
      }

      .text {
        position: absolute;
        max-width: 70%;
        font-weight: 450;
        font-size: 0.9rem;
        font-family: montserrat;
        text-align: left;
        color: white;
        margin-top: 20%;
        padding: 0rem 3rem;

        @include breakpoint("desktop") {
          padding: 0rem 2.5rem;
          font-size: 0.8rem;
        }
        @include breakpoint("mobile") {
          padding: 0rem 1.5rem;
          font-size: 0.7rem;
        }
      }
    }
  }

  .snowbehind {
    bottom: 0;
    z-index: 0;
    width: 100%;

    @include breakpoint("desktop") {
      width: 150%;
    }
  }

  .snowfront {
    bottom: -5px;
    z-index: 3;
    width: 100%;

    @media (max-width: 1100px) {
      width: 150%;
    }
  }

  .right {
    width: 700px;
    bottom: -50px;
    right: 0rem;
    z-index: 1;
    transition: width 1s ease;
    filter: FlipH;

    @media (max-width: 1550px) {
      bottom: -20px;
      width: 400px;
    }

    @include breakpoint("tablet") {
      width: 250px;
    }

    @include breakpoint("mobile") {
      width: 150px;
    }
  }
  .left {
    width: 700px;
    bottom: 10px;
    left: 0rem;
    z-index: 1;
    transition:
      width 1s ease,
      right 1s ease;
    filter: FlipH;

    @media (max-width: 1550px) {
      width: 500px;
    }

    @include breakpoint("tablet") {
      bottom: 0px;
      width: 400px;
    }

    @include breakpoint("mobile") {
      width: 200px;
    }
  }
}

.modalRight {
  position: fixed;
  width: 650px;
  bottom: -1rem;
  right: -7rem;
  z-index: 10000;

  @media (max-width: 1500px) {
    width: 500px;
    right: -5rem;
  }
  @media (max-width: 1200px) {
    width: 400px;
    right: -5rem;
  }

  @media (max-width: 900px), (max-height: 500px) {
    display: none;
  }
}
