@import "index.scss";

.root {
  flex-grow: 1;
  position: relative;
  display: flex;
  height: 100vh;
  min-height: 900px;
  overflow-x: scroll;

  &.polarPeaks {
    background-color: rgba(233, 255, 253, 255);
  }
  &.cozyReef {
    background-color: #65c7ff;
  }

  img {
    user-select: none;
    -webkit-user-drag: none;
  }

  @include breakpoint("mobile") {
    position: static;
    min-height: 760px;
    height: 100%;
  }
}

.body {
  width: 100%;
  display: flex;

  .message {
    text-align: center;
  }
  @include breakpoint("mobile") {
    display: none;
  }
}

.bannerWrapper {
  position: relative;
  transition: top 2s ease;
  top: -90px;

  @media (max-width: 1600px) {
    top: 0px;
  }
}

.soundContainer {
  display: flex;
  left: 0;
  right: 0;
}

.sound {
  position: absolute;
  bottom: 5rem;
  right: 5rem;

  &.hide {
    display: none;
  }

  @include breakpoint("mobile") {
    display: none;
  }
}

.snowflake {
  width: var(--size);
  height: var(--size);
  background: #e9fffd;
  border-radius: 50%;
  position: absolute;
  top: -25vh;
}

@keyframes snowfall {
  0% {
    transform: translate3d(var(--left-ini), 0, 0);
  }
  100% {
    transform: translate3d(var(--left-end), 110vh, 0);
  }
}

@for $i from 1 through 50 {
  .snowflake:nth-child(#{$i}) {
    --size: 0.3vw;
    --left-ini: #{random(20) - 10}vw;
    --left-end: #{random(20) - 10}vw;
    left: #{random(100)}vw;
    animation: snowfall #{5 + random(10)}s linear infinite;
    animation-delay: -#{random(10)}s;
  }
}

.snowflake:nth-child(2n) {
  --size: 0.2vw;
  filter: blur(1px);
  opacity: 0.35;
}
