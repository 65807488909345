@import "../../../index.scss";

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  img {
    max-width: 100%;
  }

  * {
    font-family: montserrat;
  }
}

.section1 {
  background-image: url("../../../../public/assets/images/collection/cozypenguins/1/1_bg.png");
  background-repeat: repeat;
  min-height: calc(max(100vh, 55rem));
  padding-top: 10rem;

  @include breakpoint("mobile") {
    padding-top: 2rem;
  }
}

.section2 {
  padding-top: 12rem;
  padding-bottom: 18rem;

  @include breakpoint("mobile") {
    padding-top: 10rem;
  }
}

.section2:before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  background-image: url("../../../../public/assets/images/collection/cozypenguins/2/bg_blur.png");
  background-size: cover;
  z-index: 1;
}

.section2:after {
  content: " ";
  position: absolute;
  width: 65%;
  height: 100%;
  bottom: 0;
  opacity: 0.75;
  background-color: #585d72;
  border: solid black;
  border-width: 0px 4px;
  z-index: 1;
  @include breakpoint("mobile") {
    display: none;
  }
}

.sectionExplorers {
  padding-top: 25rem;
  padding-bottom: 20rem;

  @include breakpoint("mobile") {
    padding-top: 12rem;
  }

  .logo {
    position: relative;
    display: flex;
    justify-content: center;
    width: 60%;
    bottom: 100px;

    @include breakpoint("mobile") {
      bottom: 50px;
    }
  }

  .wrapper {
    padding: 1rem;
    @include breakpoint("mobile") {
      padding: 0rem;
    }
  }

  .penguin {
    bottom: 0rem;
    width: 28rem;
    right: 8rem;
    z-index: 2;
    transition:
      right 1s ease,
      width 1s ease;

    @media (max-width: 1750px) {
      right: 3rem;
      width: 22rem;
    }

    @media (max-width: 1300px) {
      right: 1rem;
      width: 15rem;
    }

    @include breakpoint("mobile") {
      width: 7.5rem;
    }
  }

  .snowfront {
    bottom: -5px;
    z-index: 3;
    width: 100%;

    @media (max-width: 1300px) {
      width: 150%;
    }
  }

  .duck {
    bottom: -10px;
    left: 22rem;
    width: 150px;
    z-index: 2;
    transition:
      width 1s ease,
      left 1s ease;

    @media (max-width: 1750px) {
      width: 130px;
      left: 15rem;
    }

    @media (max-width: 1300px) {
      width: 100px;
      left: 10rem;
    }

    @include breakpoint("mobile") {
      width: 40px;
      left: 2rem;
      bottom: -10px;
    }
  }
}

.sectionExplorers:before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  background-image: url("../../../../public/assets/images/collection/cozypenguins/cpexplorers/polar_bg.png");
  background-size: cover;
  background-position: center;
  z-index: 1;
}

.sectionExplorers:after {
  content: " ";
  position: absolute;
  width: 65%;
  height: 100%;
  bottom: 0;
  opacity: 0.75;
  background-color: #585d72;
  border: solid black;
  border-width: 0px 4px;
  z-index: 1;
  @include breakpoint("mobile") {
    display: none;
  }
}

.section3 {
  padding-top: 25rem;
  padding-bottom: 5rem;

  @include breakpoint("mobile") {
    padding-top: 17.5rem;
  }

  @media only screen and (min-width: calc(960px + 2 * 18rem)) {
    padding-bottom: 10rem;
  }
}
.section3:before {
  content: " ";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../../../public/assets/images/collection/cozypenguins/3/bg_blur.png");
  background-size: cover;
}
.section3:after {
  content: " ";
  position: absolute;
  width: 65%;
  height: 100%;
  bottom: 0;
  opacity: 0.75;
  background-color: #585d72;
  border: solid black;
  border-width: 0px 4px;
  z-index: 1;

  @include breakpoint("mobile") {
    display: none;
  }
}

.section4 {
  padding-top: 8rem;
  padding-bottom: 5rem;

  @include breakpoint("mobile") {
    padding-top: 10rem;
  }

  @media only screen and (min-width: calc(960px + 2 * 18rem)) {
    padding-bottom: 20rem;
  }
}
.section4:before {
  content: " ";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../../../public/assets/images/collection/cozypenguins/4/bg_blur.png");
  background-size: cover;
}

.section4:after {
  content: " ";
  position: absolute;
  width: 65%;
  height: 100%;
  bottom: 0;
  opacity: 0.75;
  background-color: #585d72;
  border: solid black;
  border-width: 0px 4px;
  z-index: 1;

  @include breakpoint("mobile") {
    display: none;
  }
}

.adoptBanner {
  transform: translateY(45%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  position: relative;
  top: -3rem;

  @include breakpoint("mobile") {
    transform: translateY(0);
    top: 0rem;
    width: 100%;
  }

  .buttons {
    position: relative;
    display: grid;
    justify-content: center;
    gap: 0.5rem;
    grid-auto-flow: column;
    top: 10px;

    @include breakpoint("mobile") {
      grid-auto-flow: row;
      top: 0px;
    }

    .button {
      display: flex;
      align-items: center;
      cursor: pointer;

      &:active:hover {
        position: relative;
        top: 0.3rem;
      }
      .text {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        left: -50px;
        width: 140px;
        font-weight: 700;
        font-size: 1.4rem;
        height: 65%;
        padding-left: 60px;
        z-index: 0;
        @include breakpoint("mobile") {
          font-size: 1.1rem;
          width: 100px;
        }
      }

      .text:before {
        position: absolute;
        content: "";
        width: 100%;
        left: 0px;
        z-index: -1;
        height: 90%;
        border: 4px solid black;
        border-radius: 5px;
        transform: skew(-25deg);
        transform-origin: right bottom;
        background-color: white;
      }

      img {
        width: 5rem;
        z-index: 2;

        @include breakpoint("mobile") {
          width: 4rem;
        }
      }
    }
  }
}
.title {
  position: absolute;
  top: -5rem;
  right: 2rem;
  font-size: 7rem;
  font-family: DonJoseBlack;
  color: white;

  @include breakpoint("mobile") {
    top: 2rem;
    right: 2rem;
    font-size: 4rem;
  }
  text-shadow: var(--text-shadow-big);
}
