.root {
  &:hover {
    transform: scale(1.1);
  }

  a {
    img {
      margin-top: 0.25rem;
      filter: invert(1);
      max-width: 1.5rem;
    }
  }
}
