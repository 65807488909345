.highVolume {
  width: 45px;
  height: 45px;
  background: url("../../../public/assets/images/buttons/btn_soundon1.png")
    center/cover no-repeat;

  &:hover {
    background: url("../../../public/assets/images/buttons/btn_soundon0.png")
      center/cover no-repeat;
  }
}

.mute {
  width: 45px;
  height: 45px;
  background: url("../../../public/assets/images/buttons/btn_soundoff1.png")
    center/cover no-repeat;

  &:hover {
    background: url("../../../public/assets/images/buttons/btn_soundoff0.png")
      center/cover no-repeat;
  }
}
