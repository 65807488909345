@import "../../index.scss";

.button {
  --hover-scale: 1.05;

  height: 2.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  @include breakpoint("mobile") {
    img {
      max-width: 80%;
    }
  }

  &.image {
    max-width: 100%;
    height: auto;
  }

  &.bg {
    background-color: #4387ec;
    cursor: pointer;
    position: relative;
    padding: 0.75rem;
    border: 2px solid black;
    border-radius: 4rem;
    box-shadow: 0 0.4rem 0 0 rgba(0, 0, 0, 1);
    color: white;
  }

  @include breakpoint("mobile") {
    padding: 0;
  }

  user-select: none;
  cursor: pointer;

  &:active:hover {
    position: relative;
    top: 0.3rem;

    &.bg {
      box-shadow: 0 0 0 0.25rem white;
    }
  }

  &.circle {
    aspect-ratio: 1;
    font-size: 1.75rem;
    padding: 0;
  }

  &.flat {
    background-color: white;
    color: #af34df;
    box-shadow: none;
    border: none;

    will-change: transform;
    transition: transform 0.1s;

    &:hover {
      transform: scale(var(--hover-scale));
    }

    &:active:hover {
      box-shadow: none;
      top: unset;
    }
  }

  &.disabled {
    img {
      filter: grayscale(100%);
    }

    &:hover {
      transform: scale(1);
      cursor: default;
    }
  }

  &.text {
    color: #ffffff;
    text-transform: uppercase;
    font-family: montserrat;
    font-weight: 900;
    position: relative;

    .content {
      position: absolute;
      text-align: center;
      font-size: 2.5rem;
      z-index: 5;

      @include breakpoint("mobile") {
        font-size: 1.5rem;
      }

      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      white-space: nowrap;
    }
  }
}

.btnDonJoseBlack {
  background-color: #4387ec;
  cursor: pointer;
  position: relative;
  padding: 1rem;
  border: 4px solid black;
  border-radius: 1rem;
  box-shadow: 0 0.4rem 0 0 rgba(0, 0, 0, 1);
  color: white;
  user-select: none;

  a {
    padding: 0;
    color: inherit;
  }

  &:hover {
    background-color: #ffffff;
    color: #4387ec;
  }

  &:active:hover {
    position: relative;
    top: 0.3rem;
  }

  div {
    @extend .textOutline;
    --stroke-color: black;
    --stroke-width: var(--shadow-size, 6px);
    padding-right: 2rem;
    padding-left: 2rem;
    font-family: DonJoseBlack;
    font-size: var(--font-size, 3rem);
    text-align: center;
    text-transform: uppercase;
    line-height: 1;

    @include breakpoint("mobile") {
      font-size: 2rem;
      padding-right: 1rem;
      padding-left: 1rem;
      --stroke-width: var(--shadow-size, 4px);
    }

    &.smallMobile {
      @include breakpoint("mobile") {
        font-size: 1.5rem;
      }
    }
  }

  &.color {
    background-color: var(--custom-color);

    &:hover {
      background-color: #ffffff;
      color: var(--custom-color);
    }
  }

  &.disabled {
    background-color: #b7b7b7;
    cursor: default;
    &:hover {
      background-color: #b7b7b7;
      color: #ffffff;
    }
  }

  &.rounded {
    border-radius: 4rem;
  }
}
