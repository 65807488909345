.avatar {
  position: absolute;
  height: auto;

  img {
    width: 100%;
  }

  &.hideMobile {
    @media only screen and (max-width: calc(960px + 2 * 18rem)) {
      display: none;
    }
  }
}
