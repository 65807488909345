@import "../../../index.scss";

.button {
  @extend .breathingButton;
  div {
    @include breakpoint("mobile") {
      font-size: 1.5rem !important;
      --stroke-width: 3px;
    }
  }
}

.postTitle {
  font-family: DonJoseBlack;
  font-weight: 900;
  font-size: 3rem;
  padding: 2rem 0 4rem 0;
}
