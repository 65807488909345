@import "../../index.scss";

.root {
  .section1 {
    height: 100vh;
    background-image: url("../../../public/assets/images/home/fish-n-friends/bg_splash.png");
    background-repeat: no-repeat;
    background-position: center;
    min-height: 1050px;

    @include breakpoint("tablet") {
      min-height: 700px;
    }

    .content {
      display: flex;
      flex-direction: column;
      max-width: 40rem;
      align-items: center;
      gap: 2rem;
      z-index: 10;
      @include breakpoint("tablet") {
        gap: 1rem;
        padding-bottom: 7rem;
        margin-top: 4rem;
      }

      .logoFishinFriends {
        position: relative;
        width: 350px;

        @include breakpoint("mobile") {
          width: 250px;
        }
      }

      .comingSoon {
        @extend .textOutline;
        color: #fff;
        margin-top: -2rem;
        margin-bottom: 3rem;
        font-size: 2rem;
        --stroke-width: var(--shadow-size, 2px);

        @include breakpoint("mobile") {
          font-size: 1.5rem;
        }
      }

      .sign {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 550px;
        margin-bottom: 32px;
        margin-left: auto;
        margin-right: auto;

        @include breakpoint("mobile") {
          width: 300px;
        }

        .panelInput {
          background-image: url("../../../public/assets/images/home/fish-n-friends/input_panel.png");
          background-size: cover;
          background-repeat: no-repeat;
          background-color: transparent;
          border: none;
          width: 450px;
          height: 106px;
          text-align: center;
          font-family: montserrat;
          color: #ffffff;
          font-weight: 600;
          font-size: 1.2rem;
          padding-top: 16px;
          &::placeholder {
            color: rgba(255, 255, 255, 0.75);
          }
          &:focus {
            outline: none;
          }

          @include breakpoint("mobile") {
            width: 325px;
            height: 73px;
            font-size: 1rem;
          }
        }
        .stayInformed {
          width: 350px;
          margin-bottom: -28px;
          position: relative;
          padding-left: 100px;
          padding-right: 100px;
          @include breakpoint("mobile") {
            width: 200px;
            margin-bottom: -16px;
          }
        }
        .stayInformedText {
          font-family: montserrat;
          color: #ffffff;
          font-weight: 600;
          font-size: 1.4rem;
          position: absolute;
          top: 16px;
          @include breakpoint("mobile") {
            font-size: 1rem;
            top: 8px;
          }
        }
      }

      .signUpButton {
        width: 300px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .rescue {
      bottom: -1rem;
      width: 800px;
      right: 8rem;
      z-index: 2;
      transition:
        right 1s ease,
        width 1s ease;

      @media (max-width: 1750px) {
        right: 3rem;
        width: 500px;
      }

      @media (max-width: 1400px) {
        right: -1rem;
        width: 400px;
      }

      @include breakpoint("tablet") {
        width: 300px;
      }

      @include breakpoint("mobile") {
        width: 200px;
      }
    }
  }

  .section2 {
    height: 100vh;
    background-image: url("../../../public/assets/images/home/2/Background.png");
    background-repeat: no-repeat;
    background-position: center;
    min-height: 600px;

    .carouselImg {
      width: 22rem;

      @include breakpoint("desktop") {
        width: 15rem;
      }
    }
  }

  .section3 {
    height: 100vh;
    background-image: url("../../../public/assets/images/home/3/Background.png");
    background-repeat: no-repeat;
    background-position: center;
    min-height: 600px;

    @include breakpoint("mobile") {
      padding-bottom: 6rem;
    }

    .skater {
      bottom: -4rem;
      min-width: 40rem;
      right: 0;

      @include breakpoint("desktop") {
        bottom: -2rem;
      }
    }
  }

  .section4 {
    height: 100vh;
    background-image: url("../../../public/assets/images/home/4/Regions.png");
    background-repeat: no-repeat;
    background-position: center;
    min-height: 800px;

    .explorer {
      left: 8rem;
      bottom: -1rem;

      @include breakpoint("desktop") {
        max-width: 50%;
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
      }
      @include breakpoint("mobile") {
        display: none;
      }
    }
    .buttonWrapper {
      padding-top: 150px;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      @include breakpoint("mobile") {
        gap: 0.5rem;
        padding-top: 70px;
      }
    }
  }

  .section5 {
    height: 100vh;
    background-image: url("../../../public/assets/images/home/5/Woofys_background.png");
    background-repeat: repeat;
    background-position: center;
    min-height: 600px;

    @include breakpoint("mobile") {
      padding-bottom: 6rem;
    }

    .aceBig {
      width: 450px;
      bottom: -5px;
      z-index: 2;
      left: 0;
      transition: width 1s ease;

      @media (max-width: 1750px) {
        width: 400px;
      }
      @media (max-width: 1400px) {
        width: 300px;
        left: -4rem;
      }
      @include breakpoint("tablet") {
        width: 250px;
      }
      @include breakpoint("mobile") {
        width: 200px;
      }
    }
    .iceAxe {
      width: 500px;
      right: -3rem;
      bottom: -5px;
      z-index: 2;
      transition: width 1s ease;

      @media (max-width: 1750px) {
        width: 450px;
      }
      @media (max-width: 1400px) {
        width: 300px;
      }
      @include breakpoint("tablet") {
        width: 250px;
      }
      @include breakpoint("mobile") {
        width: 200px;
        bottom: -15px;
      }
    }
  }

  .section6 {
    height: 100vh;
    background-image: url("../../../public/assets/images/home/6/Background.png");
    background-repeat: repeat;
    background-position: center;
    min-height: 600px;

    @include breakpoint("mobile") {
      padding-bottom: 6rem;
    }

    .gasha {
      bottom: -1rem;
      left: 50%;
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      width: 50%;

      @include breakpoint("mobile") {
        width: 90%;
      }
    }
  }

  .appStoreMobileButtons {
    display: none;

    @include breakpoint("mobile") {
      display: flex;
    }
  }

  .appStoreDesktopButtons {
    @include breakpoint("mobile") {
      display: none;
    }
  }

  .snowbehind {
    bottom: -15px;
    z-index: 0;
    width: 100%;

    @media (max-width: 1300px) {
      width: 150%;
      bottom: -5px;
    }
  }

  .snowfront {
    bottom: -5px;
    z-index: 3;
    width: 100%;

    @media (max-width: 1300px) {
      width: 150%;
    }
  }
}
