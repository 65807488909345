@import "../../index.scss";

.carousel {
  display: flex;
  flex-direction: row;
  align-items: center;

  @include breakpoint("mobile") {
    gap: 0.8rem;
  }

  .arrow {
    &:hover {
      transform: scale(1.1);
    }
  }

  .items {
    display: flex;
    flex-direction: row;
  }

  .item {
    padding: 0.25rem;
    cursor: pointer;
    max-width: 25rem;

    img {
      max-width: 25rem;
    }
  }

  .button {
    img {
      @include breakpoint("mobile") {
        width: 2.5rem;
      }
    }
  }
}
