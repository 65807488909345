@import "../../../../index.scss";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  min-height: 1000px;
  background-image: url("../../../../../public/assets/images/region/polarpeaks/portal/bg_ice.png");
  background-size: cover;

  @include breakpoint("mobile") {
    min-height: 1200px;
    flex-direction: row;
    align-items: center;
    overflow: clip;
  }

  .contents {
    position: relative;
    width: 65%;
    padding: 12rem 6rem 2rem 6rem;
    height: calc(100% - (12rem));
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 1rem;
    min-width: 650px;
    background-color: rgba(75, 111, 154, 0.7);
    border-right: 0.5rem solid black;
    border-left: 0.5rem solid black;

    @include breakpoint("mobile") {
      min-width: auto;
      flex-direction: column;
      align-items: center;
      overflow: clip;
      padding: 6rem 6rem;
      border-right: 0.5rem solid black;
      border-left: 0.5rem solid black;
    }
  }

  .snowbehind {
    bottom: -30px;
    z-index: 0;
    width: 100%;

    @media (max-width: 1300px) {
      width: 150%;
    }
    @include breakpoint("mobile") {
      bottom: -10px;
    }
  }

  .snowfront {
    bottom: -5px;
    z-index: 3;
    width: 100%;

    @media (max-width: 1300px) {
      width: 150%;
    }
  }

  .rock1 {
    right: 5rem;
    bottom: 25rem;
    transition: right 2s ease;
    z-index: 2;

    @media (max-width: 1750px) {
      right: 1rem;
    }
    @include breakpoint("tablet") {
      display: none;
    }
  }

  .rock2 {
    right: 12rem;
    top: 10rem;
    transition: right 2s ease;
    z-index: 2;

    @media (max-width: 1750px) {
      right: 4rem;
    }
    @include breakpoint("tablet") {
      display: none;
    }
  }

  .rock3 {
    right: 18rem;
    bottom: 5rem;
    transition: right 2s ease;
    z-index: 2;

    @media (max-width: 1750px) {
      right: 6rem;
    }
    @include breakpoint("tablet") {
      display: none;
    }
  }
  .rock4 {
    left: 18rem;
    top: 15rem;
    transition: left 2s ease;
    z-index: 2;

    @media (max-width: 1750px) {
      left: 6rem;
    }
    @include breakpoint("tablet") {
      display: none;
    }
  }
  .rock5 {
    left: 4rem;
    bottom: 20rem;
    transition: left 2s ease;
    z-index: 2;

    @media (max-width: 1750px) {
      left: 1rem;
    }
    @include breakpoint("tablet") {
      display: none;
    }
  }
  .rock6 {
    left: 20rem;
    bottom: 5rem;
    transition: left 2s ease;
    z-index: 2;

    @media (max-width: 1750px) {
      left: 8rem;
    }
    @include breakpoint("tablet") {
      display: none;
    }
  }
}
