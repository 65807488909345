@import "../../../index.scss";

.tabWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 350px;

  .tabButtons {
    display: grid;
    grid-template-columns: 1.2fr 1fr 1fr 1fr 1.2fr;
    width: 90%;
    align-items: center;
    justify-content: center;

    .backButtonWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      .backButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        background: url("../../../../public/assets/images/buttons/btn_back1.png")
          center/cover no-repeat;

        &:hover {
          background: url("../../../../public/assets/images/buttons/btn_back0.png")
            center/cover no-repeat;
        }
      }
    }
    .tabButton {
      position: relative;
      cursor: pointer;
      width: 45px;
      height: 45px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      align-self: center;
      justify-self: center;
      margin: 0.5rem 0rem;

      @include breakpoint("mobile") {
        width: 40px;
      }

      &:hover {
        transition: scale 0.5s ease;
        scale: 1.1;
      }
      &.active {
        scale: 1.1;
      }
      &.cozy {
        background-image: url("../../../../public/assets/images/region/polarpeaks/rescue/icon_cozy.png");
        opacity: 0.5;
        &.active {
          opacity: 1;
        }
      }
      &.explorer {
        background-image: url("../../../../public/assets/images/region/polarpeaks/rescue/icon_explorer.png");
        opacity: 0.5;
        &.active {
          opacity: 1;
        }
      }
      &.find {
        background-image: url("../../../../public/assets/images/region/polarpeaks/rescue/icon_find.png");
        opacity: 0.5;
        &.active {
          opacity: 1;
        }
      }
      &.capsules {
        width: 55px;
        height: 60px;
        background-image: url("../../../../public/assets/images/region/polarpeaks/campsite/btn_capsule0.png");
        &.active {
          background-image: url("../../../../public/assets/images/region/polarpeaks/campsite/btn_capsule1.png");
        }
      }
      &.woofys {
        width: 55px;
        height: 60px;
        background-image: url("../../../../public/assets/images/region/polarpeaks/campsite/btn_woofy0.png");
        &.active {
          background-image: url("../../../../public/assets/images/region/polarpeaks/campsite/btn_woofy1.png");
        }
      }
      &.checker {
        width: 55px;
        height: 60px;
        background-image: url("../../../../public/assets/images/region/polarpeaks/campsite/btn_find0.png");
        &.active {
          background-image: url("../../../../public/assets/images/region/polarpeaks/campsite/btn_find1.png");
        }
      }
    }
  }
}
.indicatorTitle {
  @extend .textOutline;
  position: relative;
  --stroke-color: black;
  --stroke-width: 3px;
  color: #ffc758;
  font-family: DonJoseBlack;
  font-size: 1.2rem;
  text-align: center;
  text-transform: uppercase;
  line-height: 0.8;
  padding-top: 0.5rem;

  @include breakpoint("desktop") {
    font-size: 1rem;
  }
  @include breakpoint("mobile") {
    --stroke-width: 2.5px;
    font-size: 0.9rem;
  }
}
.indicatorContent {
  @extend .textOutline;
  position: relative;
  --stroke-color: black;
  --stroke-width: 3px;
  color: white;
  padding: 0.6rem;
  font-family: DonJoseBlack;
  font-size: 1.5rem;
  text-align: center;
  text-transform: uppercase;
  line-height: 1;
  font-family: DonJoseBlack;
  background-color: rgba(255, 255, 255, 0.3);
  border: 4px solid black;
  border-radius: 50px;

  @include breakpoint("desktop") {
    font-size: 1.4rem;
    padding: 0.6rem;
  }
  @include breakpoint("mobile") {
    font-size: 1.4rem;
    --stroke-width: 3px;
  }
}
.checkWrapper {
  position: relative;
  width: 380px;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 10px;
  border: 5px black solid;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @include breakpoint("desktop") {
    width: 280px;
  }
  @include breakpoint("mobile") {
    width: 280px;
  }

  .searchField {
    font-weight: 600;
    font-size: 1rem;
    font-family: montserrat;
    width: 90%;
    background-color: white;
    border: 5px black solid;
    border-radius: 50px;
    padding: 0.6rem 1rem;
    margin: 0.5rem;

    &:focus {
      outline: none;
    }
    @include breakpoint("desktop") {
      font-size: 0.8rem;
    }
  }

  .itemImage {
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1 / 1;
    width: 180px;
    border: 5px black solid;
    border-radius: 0.5rem;
    margin: 0.3rem;

    @include breakpoint("desktop") {
      width: 140px;
    }

    img {
      height: 100%;
      width: 100%;
    }
  }

  .statusWrapper {
    display: grid;
    grid-template-rows: 1fr 3fr;
    margin: 0.3rem;
    border: 5px black solid;
    border-radius: 0.5rem;
    width: 35%;

    .statusTitle {
      @extend .textOutline;
      --stroke-color: black;
      --stroke-width: 4px;
      background-color: #ffc758;
      text-align: center;
      color: white;
      font-family: DonJoseBlack;
      font-size: 1.5rem;
      border-bottom: 5px black solid;
      @include breakpoint("desktop") {
        font-size: 1.2rem;
        --stroke-width: 3px;
      }
    }

    .status {
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      gap: 0.5rem;

      .text {
        font-weight: 700;
        font-size: 1rem;
        font-family: montserrat;

        @include breakpoint("desktop") {
          font-size: 0.9rem;
        }
      }
      .icon {
        @extend .textOutline;
        --stroke-color: black;
        --stroke-width: 4px;
        color: white;
        font-family: DonJoseBlack;
        font-size: 1.5rem;
        border: 5px black solid;
        border-radius: 0.5rem;
        aspect-ratio: 1 / 1;
        width: 40px;

        @include breakpoint("desktop") {
          width: 30px;
          font-size: 1.2rem;
          --stroke-width: 3px;
        }

        &.check {
          background-color: #60eca5;
        }
        &.x {
          background-color: #f86459;
        }
        &.empty {
          background-color: #d8d8d8;
        }
      }
    }
  }
}
.button {
  margin-top: 20px;

  div {
    width: 100px;
    display: flex;
    justify-content: center;
    text-align: center;
    line-height: 0.8;
    font-size: 2.2rem;

    @include breakpoint("desktop") {
      line-height: 0.85;
      font-size: 1.7rem;
    }
  }
}
