@import "../../../index.scss";

.hero {
  background-image: url("../../../../public/assets/images/games/fish-n-friends/bg_splash.png");
  background-size: cover;
  min-height: calc(max(100vh, 30rem));

  @media only screen and (max-height: 800px) {
    min-height: 820px;
  }
}

.about {
  padding: 5rem 0;
  background-image: url("../../../../public/assets/images/games/shared/tile.png");
  background-repeat: repeat;
}

.cozy {
  bottom: -1rem;
  width: 800px;
  right: 8rem;
  z-index: 2;
  transition:
    right 1s ease,
    width 1s ease;

  @media (max-width: 1750px) {
    right: 3rem;
    width: 500px;
  }

  @media (max-width: 1400px) {
    right: -1rem;
    width: 400px;
  }

  @include breakpoint("tablet") {
    width: 300px;
  }

  @include breakpoint("mobile") {
    width: 200px;
  }
}

.snowfront {
  bottom: -5px;
  z-index: 3;
  width: 100%;

  @media (max-width: 1300px) {
    width: 150%;
  }
}

.appStoreMobileButtons {
  display: none;

  @include breakpoint("unity-min") {
    display: flex;
  }
}

.appStoreDesktopButtons {
  @include breakpoint("unity-min") {
    display: none;
  }
}
