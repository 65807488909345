@import "../../../index.scss";

.penguinsContainer {
  max-width: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.warpGateContainer {
  display: flex;
  flex-direction: column;
  justify-content: left;
  z-index: 2;
}

.hidden {
  visibility: hidden;
}

.warpGate {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  position: relative;
  top: 30px;

  @include breakpoint("mobile") {
    top: -100px;
  }

  .warpGateImg {
    max-height: 22rem;

    @include breakpoint("desktop") {
      max-height: 20rem;
    }
    @include breakpoint("mobile") {
      max-height: 15rem;
    }
  }

  .warpGateFloat {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    bottom: 0;
    transform: translateY(90%);

    @include breakpoint("tablet") {
      font-size: 1rem;
    }

    .warpGateSign {
      position: relative;
      width: 200px;

      @include breakpoint("desktop") {
        width: 150px;
        top: 10px;
      }
    }
    .withoutEnterButton {
      @include breakpoint("mobile") {
        width: 230px;
        transform: translateY(-30%);
      }
    }
  }
}

.confirmationPageSign {
  position: relative;
  left: -150px;
  @include breakpoint("desktop") {
    left: -110px;
  }
  @include breakpoint("mobile") {
    left: 0;
  }

  img {
    padding-left: 1rem;
    width: 70%;
    @include breakpoint("mobile") {
      width: 80%;
    }
  }
}

.textSign {
  position: relative;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include breakpoint("desktop") {
    max-width: 90%;
  }

  .sign {
    max-width: 100%;

    @include breakpoint("mobile") {
      max-width: 120%;
    }

    &.withoutEnterButton {
      max-width: 115%;
      @include breakpoint("mobile") {
        max-width: 140%;
        transform: translateY(0%);
      }
    }

    &.penguinSign {
      @include breakpoint("mobile") {
        max-width: 90%;
      }
    }
  }

  .text {
    position: absolute;
    max-width: 75%;
    font-weight: 450;
    font-size: 1.25rem;
    font-family: montserrat;
    text-align: center;

    @include breakpoint("desktop") {
      font-size: 1.1rem;
    }

    @include breakpoint("tablet") {
      font-size: 1rem;
    }
  }

  &.small {
    .text {
      font-size: 1rem;
      max-width: 80%;
      text-align: left;
      @include breakpoint("desktop") {
        font-size: 0.75rem;
        max-width: 95%;
      }
      @include breakpoint("mobile") {
        padding-left: 0rem;
      }
    }
  }
}

.nowrap {
  div {
    white-space: nowrap;

    @include breakpoint("desktop") {
      font-size: 1.7rem;
      text-shadow: 3px;
    }
  }
}

.lhs {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  position: relative;
  top: -50px;
}

.penguinSelectWrapper {
  position: relative;
  width: min-content;
}
.penguinSelect {
  max-height: 370px;
  background-color: white;
  padding: 0.6rem;
  border: 0.4rem black solid;
  border-radius: 0.5rem;
  overflow-y: scroll;
  position: relative;
  width: min-content;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  @include breakpoint("desktop") {
    max-height: 300px;
  }
  @include breakpoint("mobile") {
    max-height: 200px;
  }
}

.bridgeLimit {
  position: absolute;
  background-color: #4387ec;
  padding: 0.5rem;
  border: 4px solid black;
  border-radius: 1rem;
  color: white;
  user-select: none;
  top: -3.2rem;
  right: 0;

  @include breakpoint("mobile") {
    top: -2.8rem;
  }
  &.red {
    background-color: #f86459;
  }

  div {
    @extend .textOutline;
    --stroke-color: black;
    --stroke-width: var(--shadow-size, 4px);
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    font-family: DonJoseBlack;
    font-size: 2rem;
    text-align: center;
    text-transform: uppercase;
    line-height: 1;
    font-family: DonJoseBlack;

    @include breakpoint("mobile") {
      --stroke-width: var(--shadow-size, 3px);
      font-size: 1.5rem;
    }
  }
}

.backButton {
  position: absolute;
  top: 0;
  left: -60px;
  width: 45px;
  height: 45px;
  background: url("../../../../public/assets/images/buttons/btn_back1.png")
    center/cover no-repeat;

  &:hover {
    background: url("../../../../public/assets/images/buttons/btn_back0.png")
      center/cover no-repeat;
  }

  &.hideBackBtn {
    display: none;
  }
}

.infoGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.cr {
  position: relative;
  width: 350px;
  margin: 1rem;
  bottom: 1rem;

  @include breakpoint("mobile") {
    width: 300px;
    padding-bottom: 40px;
  }
}

.info {
  display: flex;
  flex-direction: row;
  gap: 1rem;

  .indicator {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    flex-grow: 1;
    background-color: white;
    border: 0.4rem solid black;
    border-radius: 1rem;
    box-shadow: 0 0.4rem 0 0 rgba(0, 0, 0, 1);

    .number {
      @extend .textOutline;

      padding: 0 1rem;

      font-family: DonJoseBlack;
      font-size: 2rem;
      color: white;
      font-weight: 900;

      --stroke-color: black;
      --stroke-width: 0.3rem;
    }

    .text {
      font-family: montserrat;
      font-weight: 400;
      font-size: 1.2rem;
    }
  }

  .button {
    box-shadow: 0 0.4rem 0 0 rgba(0, 0, 0, 1);
  }
}

.group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.modalButtons {
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 1rem;

  @include breakpoint("mobile") {
    flex-direction: column;
  }
}

.modal {
  display: flex;
  flex-direction: row;
}

.modalPage {
  display: flex;
  flex-direction: row;
  padding-right: 20rem;
  padding-top: 9rem;

  &.confirmationPage {
    padding-top: 15rem;

    @include breakpoint("mobile") {
      padding-top: 10rem;
    }
  }

  @media (max-width: 800px) {
    padding-right: 0;
  }

  @include breakpoint("mobile") {
    flex-direction: column;
    padding-top: 2rem;
    max-width: 330px;
  }
}

.modalContents {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pebbles {
  position: fixed;
  width: 800px;
  bottom: -1rem;
  right: 3rem;
  z-index: 10000;

  @media (max-width: 1600px) {
    width: 600px;
    right: 0rem;
  }

  @media (max-width: 1200px) {
    width: 500px;
  }

  @media (max-width: 800px) {
    display: none;
  }
}

.happyPenguin {
  position: fixed;
  bottom: 1rem;
  right: 11rem;
  width: 600px;
  z-index: 10000;

  @media (max-width: 1600px) {
    width: 500px;
    right: 5rem;
  }

  @media (max-width: 1200px) {
    width: 400px;
    right: 0rem;
  }

  @media (max-width: 800px) {
    display: none;
  }
}

.progressBar {
  position: relative;
  width: 100%;
  min-width: 30rem;

  .progressBarContainer {
    border-radius: 2rem;
    border: 0.4rem solid black;
    box-shadow: 0 0.4rem 0 0 rgb(0, 0, 0, 1);
    height: 3.5rem;
    overflow: clip;
    background-color: #475eb2;
  }

  .progressBarFill {
    --percent: 25%;
    background-color: #8cebd4;
    height: 100%;
    width: var(--percent);
    border-right: 0.5rem solid black;
  }

  .progressBarText {
    @extend .textOutline;
    --stroke-width: 0.4rem;
    --stroke-color: black;
    font-size: 1.5rem;
    font-weight: 900;
    text-transform: uppercase;
    padding-right: 2rem;

    position: absolute;
    right: 0;
    top: 0;
    transform: translateY(-80%);
    color: #8cebd4;
  }

  .progressBarHelmet {
    position: absolute;
    left: -1rem;
    top: -0.75rem;
    bottom: 0;
  }
}

.modalContents {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 9999;
}

.card {
  display: flex;
  flex-direction: column;
  border-radius: 2rem;
  border: 0.4rem solid black;
  box-shadow: 0 0.3rem 0 0 rgb(0, 0, 0, 1);
  background: white;
  overflow: clip;

  .title {
    @extend .textOutline;
    --stroke-width: 4px;
    --stroke-color: black;
    font-size: var(--font-size);
    font-weight: 900;
    font-family: DonJoseBlack;
    text-transform: uppercase;
    color: white;
    white-space: nowrap;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem 2rem;
    background-color: var(--color);

    @include breakpoint("desktop") {
      font-size: 1.5rem;
      --stroke-width: 3px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    padding: 1.5rem 2rem;
    border-top: 0.4rem solid black;

    .fee {
      border: 0.3rem solid;
      border-color: #c4c4c4;
      border-radius: 0.5rem;
      padding: 0.5rem;
      font-family: montserrat;
      font-size: 1rem;
      line-height: 1.5rem;
      margin-top: 1rem;
    }
  }

  .txHistory {
    max-height: 10rem;
    overflow-y: scroll;
    align-items: normal;
    justify-content: normal;

    @include breakpoint("mobile") {
      max-height: 3rem;
    }
  }
}

.hide {
  display: none;
}

.txItem {
  font-weight: 700;
  font-size: 1.1rem;
  font-family: montserrat;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 0.25rem;

  @include breakpoint("tablet") {
    font-size: 0.9rem;
  }

  a {
    color: black;
    text-decoration: none;
  }

  .redX {
    color: red;
    cursor: pointer;
    padding-left: 1rem;
    right: 0;
    &:hover {
      transform: scale(1.1);
    }
  }
}

.grid {
  .gridContainer {
    --grip-gap: 0.25rem;

    display: grid;
    grid-template-columns: repeat(4, 1fr);

    @include breakpoint("desktop") {
      grid-template-columns: repeat(3, 1fr);
    }

    @include breakpoint("tablet") {
      grid-template-columns: repeat(2, 1fr);
    }

    grid-gap: var(--grip-gap);
    grid-auto-flow: dense;
  }
}

.glow {
  bottom: 0;
  z-index: 1;
  width: 100vw;
  pointer-events: none;
}
