@import "../../../index.scss";

.root {
  display: flex;
  flex-direction: row;
  gap: 1rem;

  @include breakpoint("tablet") {
    display: none;
  }
}

.button {
  @extend .textOutline;

  --stroke-color: black;
  --stroke-width: 0.15rem;

  background: #5196e6;
  color: white;
  box-shadow: 0 0.25rem 0 0 rgba(0, 0, 0, 1);
  border-radius: 5rem;
  border: 0.2rem solid black;
  text-align: center;
  line-height: 1;
  font-family: DonJoseBlack;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 1rem;
  padding: 0.75rem 1.25rem;
  white-space: nowrap;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  .cross {
    display: flex;
    img {
      max-width: 1.25rem;
    }
  }

  &:hover {
    transform: scale(1.05);
  }
}
