@import "../../../../index.scss";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  min-height: 950px;
  background-image: url("../../../../../public/assets/images/region/cozyreef/portal/bg_cozyreef.png");
  background-size: cover;
  overflow-y: hidden;

  @include breakpoint("mobile") {
    min-height: 1150px;
    flex-direction: row;
    align-items: center;
    overflow: clip;
  }

  .contents {
    position: relative;
    width: 65%;
    border-right: 0.5rem solid black;
    border-left: 0.5rem solid black;
    padding: 15rem 6rem 2rem 6rem;
    height: calc(100% - (12rem + 2rem));
    background-color: rgba(75, 111, 154, 0.7);
    min-width: 650px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 1rem;

    @include breakpoint("mobile") {
      min-width: auto;
      flex-direction: column;
      align-items: center;
      overflow: clip;
      padding: 10rem 6rem;
    }
  }

  .rocksL {
    width: 120px;
    left: 7rem;
    top: 20rem;

    transition: left 2s ease;

    @media (max-width: 1750px) {
      left: -1rem;
    }
    @include breakpoint("tablet") {
      display: none;
    }
  }

  .rocksR {
    width: 100px;
    right: 9rem;
    top: 10rem;

    transition: right 2s ease;

    @media (max-width: 1750px) {
      right: 0rem;
    }
    @include breakpoint("tablet") {
      display: none;
    }
  }
}
