@import "../../index.scss";

.panel {
  position: relative;
  color: #ffffff;

  .content {
    position: absolute;
    bottom: 6.5rem;
    width: 100%;
    text-align: center;

    @include breakpoint("mobile") {
      bottom: 3.75rem;
    }

    * {
      margin: auto;
    }

    .title {
      font-weight: 900;
      font-size: 4rem;

      @include breakpoint("mobile") {
        font-size: 10vw;
      }
    }

    .subtitle {
      font-weight: 500;
      font-size: 3rem;
      letter-spacing: 0.5rem;

      @include breakpoint("mobile") {
        font-size: 5vw;
        letter-spacing: 0.25rem;
      }
    }
  }

  .button {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -3rem;
    margin: auto;

    display: flex;
    justify-content: center;

    @include breakpoint("mobile") {
      bottom: -2rem;
      max-width: 75%;
    }
  }
}
