html,
body {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: montserrat;
  src: url("../public/assets/fonts/Montserrat-VariableFont_wght.ttf")
    format("truetype-variations");
  font-weight: 1 999;
}

@font-face {
  font-family: DonJoseBlack;
  src: url("../public/assets/fonts/DonJose-Black.otf") format("opentype");
  font-weight: 1 999;
}

.breathingButton {
  -webkit-animation: breathing 5s ease-out infinite normal;
  animation: breathing 5s ease-out infinite normal;
}

@-webkit-keyframes breathing {
  0%,
  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes breathing {
  0%,
  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  50% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

$breakpoints: (
  "mobile": 540px,
  "unity-min": 960px,
  "tablet": 768px,
  "desktop": 1024px,
) !default;

@mixin breakpoint($breakpoint) {
  @media (max-width: map-get($breakpoints, $breakpoint)) {
    @content;
  }
}

:root {
  --stroke-color: black;

  --stroke-width: 4px;
  --text-shadow: calc(var(--stroke-width) * 1) calc(var(--stroke-width) * 0) 1px
      var(--stroke-color),
    calc(var(--stroke-width) * 0.9239) calc(var(--stroke-width) * 0.3827) 1px
      var(--stroke-color),
    calc(var(--stroke-width) * 0.7071) calc(var(--stroke-width) * 0.7071) 1px
      var(--stroke-color),
    calc(var(--stroke-width) * 0.3827) calc(var(--stroke-width) * 0.9239) 1px
      var(--stroke-color),
    calc(var(--stroke-width) * 0) calc(var(--stroke-width) * 1) 1px
      var(--stroke-color),
    calc(var(--stroke-width) * -0.3827) calc(var(--stroke-width) * 0.9239) 1px
      var(--stroke-color),
    calc(var(--stroke-width) * -0.7071) calc(var(--stroke-width) * 0.7071) 1px
      var(--stroke-color),
    calc(var(--stroke-width) * -0.9239) calc(var(--stroke-width) * 0.3827) 1px
      var(--stroke-color),
    calc(var(--stroke-width) * -1) calc(var(--stroke-width) * 0) 1px
      var(--stroke-color),
    calc(var(--stroke-width) * -0.9239) calc(var(--stroke-width) * -0.3827) 1px
      var(--stroke-color),
    calc(var(--stroke-width) * -0.7071) calc(var(--stroke-width) * -0.7071) 1px
      var(--stroke-color),
    calc(var(--stroke-width) * -0.3827) calc(var(--stroke-width) * -0.9239) 1px
      var(--stroke-color),
    calc(var(--stroke-width) * 0) calc(var(--stroke-width) * -1) 1px
      var(--stroke-color),
    calc(var(--stroke-width) * 0.3827) calc(var(--stroke-width) * -0.9239) 1px
      var(--stroke-color),
    calc(var(--stroke-width) * 0.7071) calc(var(--stroke-width) * -0.7071) 1px
      var(--stroke-color),
    calc(var(--stroke-width) * 0.9239) calc(var(--stroke-width) * -0.3827) 1px
      var(--stroke-color);

  --stroke-width-big: 6px;
  --text-shadow-big: calc(var(--stroke-width-big) * 1)
      calc(var(--stroke-width-big) * 0) 1px var(--stroke-color),
    calc(var(--stroke-width-big) * 0.9239)
      calc(var(--stroke-width-big) * 0.3827) 1px var(--stroke-color),
    calc(var(--stroke-width-big) * 0.7071)
      calc(var(--stroke-width-big) * 0.7071) 1px var(--stroke-color),
    calc(var(--stroke-width-big) * 0.3827)
      calc(var(--stroke-width-big) * 0.9239) 1px var(--stroke-color),
    calc(var(--stroke-width-big) * 0) calc(var(--stroke-width-big) * 1) 1px
      var(--stroke-color),
    calc(var(--stroke-width-big) * -0.3827)
      calc(var(--stroke-width-big) * 0.9239) 1px var(--stroke-color),
    calc(var(--stroke-width-big) * -0.7071)
      calc(var(--stroke-width-big) * 0.7071) 1px var(--stroke-color),
    calc(var(--stroke-width-big) * -0.9239)
      calc(var(--stroke-width-big) * 0.3827) 1px var(--stroke-color),
    calc(var(--stroke-width-big) * -1) calc(var(--stroke-width-big) * 0) 1px
      var(--stroke-color),
    calc(var(--stroke-width-big) * -0.9239)
      calc(var(--stroke-width-big) * -0.3827) 1px var(--stroke-color),
    calc(var(--stroke-width-big) * -0.7071)
      calc(var(--stroke-width-big) * -0.7071) 1px var(--stroke-color),
    calc(var(--stroke-width-big) * -0.3827)
      calc(var(--stroke-width-big) * -0.9239) 1px var(--stroke-color),
    calc(var(--stroke-width-big) * 0) calc(var(--stroke-width-big) * -1) 1px
      var(--stroke-color),
    calc(var(--stroke-width-big) * 0.3827)
      calc(var(--stroke-width-big) * -0.9239) 1px var(--stroke-color),
    calc(var(--stroke-width-big) * 0.7071)
      calc(var(--stroke-width-big) * -0.7071) 1px var(--stroke-color),
    calc(var(--stroke-width-big) * 0.9239)
      calc(var(--stroke-width-big) * -0.3827) 1px var(--stroke-color);

  @include breakpoint("mobile") {
    --stroke-width: 3px;
    --stroke-width-big: 4px;
  }
}

.textOutline {
  text-shadow:
    calc(var(--stroke-width) * 1) calc(var(--stroke-width) * 0) 1.5px
      var(--stroke-color),
    calc(var(--stroke-width) * 0.9239) calc(var(--stroke-width) * 0.3827) 1.5px
      var(--stroke-color),
    calc(var(--stroke-width) * 0.7071) calc(var(--stroke-width) * 0.7071) 1.5px
      var(--stroke-color),
    calc(var(--stroke-width) * 0.3827) calc(var(--stroke-width) * 0.9239) 1.5px
      var(--stroke-color),
    calc(var(--stroke-width) * 0) calc(var(--stroke-width) * 1) 1.5px
      var(--stroke-color),
    calc(var(--stroke-width) * -0.3827) calc(var(--stroke-width) * 0.9239) 1.5px
      var(--stroke-color),
    calc(var(--stroke-width) * -0.7071) calc(var(--stroke-width) * 0.7071) 1.5px
      var(--stroke-color),
    calc(var(--stroke-width) * -0.9239) calc(var(--stroke-width) * 0.3827) 1.5px
      var(--stroke-color),
    calc(var(--stroke-width) * -1) calc(var(--stroke-width) * 0) 1.5px
      var(--stroke-color),
    calc(var(--stroke-width) * -0.9239) calc(var(--stroke-width) * -0.3827)
      1.5px var(--stroke-color),
    calc(var(--stroke-width) * -0.7071) calc(var(--stroke-width) * -0.7071)
      1.5px var(--stroke-color),
    calc(var(--stroke-width) * -0.3827) calc(var(--stroke-width) * -0.9239)
      1.5px var(--stroke-color),
    calc(var(--stroke-width) * 0) calc(var(--stroke-width) * -1) 1.5px
      var(--stroke-color),
    calc(var(--stroke-width) * 0.3827) calc(var(--stroke-width) * -0.9239) 1.5px
      var(--stroke-color),
    calc(var(--stroke-width) * 0.7071) calc(var(--stroke-width) * -0.7071) 1.5px
      var(--stroke-color),
    calc(var(--stroke-width) * 0.9239) calc(var(--stroke-width) * -0.3827) 1.5px
      var(--stroke-color);
}

.snowflake {
  --size: 1vw;
  width: var(--size);
  height: var(--size);
  background: #e9fffd;
  border-radius: 50%;
  position: absolute;
  top: -5vh;
  z-index: 4;
}

@keyframes snowfall {
  0% {
    transform: translate3d(var(--left-ini), 0, 0);
  }
  100% {
    transform: translate3d(var(--left-end), 110vh, 0);
  }
}

@for $i from 1 through 50 {
  .snowflake:nth-child(#{$i}) {
    --size: 0.4vw;
    --left-ini: #{random(20) - 10}vw;
    --left-end: #{random(20) - 10}vw;
    left: #{random(100)}vw;
    animation: snowfall #{5 + random(10)}s linear infinite;
    animation-delay: -#{random(10)}s;
  }
}

.snowflake:nth-child(2n) {
  --size: 0.2vw;
  filter: blur(1px);
  opacity: 0.35;
}

.confetti {
  --width: 0.1vw;
  --height: 0.4vw;
  width: var(--width);
  height: var(--height);
  position: fixed;
  z-index: 100000;
}

@keyframes confetti-shoot-left {
  0% {
    transform: translate3d(0, 0, 0) rotate(0deg);
  }
  50% {
    transform: translate3d(var(--left), calc(var(--top) * -1), 0) rotate(180deg);
  }
  100% {
    transform: translate3d(var(--left), 0, 0) rotate(0deg);
  }
}

@keyframes confetti-shoot-right {
  0% {
    transform: translate3d(0, 0, 0) rotate(0deg);
  }
  50% {
    transform: translate3d(calc(var(--right) * -1), calc(var(--top) * -1), 0)
      rotate(180deg);
  }
  100% {
    transform: translate3d(calc(var(--right) * -1), 0, 0) rotate(0deg);
  }
}

@for $i from 1 through 100 {
  .confetti:nth-child(#{$i}) {
    --width: #{random(1) / 3}vw;
    --height: #{random(2) / 3}vw;
    --right: #{15 + random(20)}vw;
    --top: #{50 + random(30)}vh;
    right: calc(#{random(3)}vw - 50px);
    bottom: -5vh;
    animation: confetti-shoot-right ease-out infinite;
    @include breakpoint("mobile") {
      --width: #{random(1) / 1.5}vw;
      --height: #{random(2) / 1.5}vw;
      --right: #{10 + random(30)}vw;
      --top: #{25 + random(30)}vh;
      right: calc(#{random(3)}vw - 20px);
    }
  }
}
@for $i from 100 through 200 {
  .confetti:nth-child(#{$i}) {
    --width: #{random(1) / 3}vw;
    --height: #{random(2) / 3}vw;
    --left: #{15 + random(20)}vw;
    --top: #{50 + random(30)}vh;
    left: calc(#{random(3)}vw - 50px);
    bottom: -5vh;
    animation: confetti-shoot-left ease-out infinite;
    @include breakpoint("mobile") {
      --width: #{random(1) / 1.5}vw;
      --height: #{random(2) / 1.5}vw;
      --left: #{10 + random(30)}vw;
      --top: #{25 + random(30)}vh;
      left: calc(#{random(3)}vw - 20px);
    }
  }
}
