@import "../../index.scss";

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  min-height: 1000px;
  overflow-y: hidden;

  @include breakpoint("mobile") {
    min-height: auto;
    height: min-content;
    align-items: center;
  }

  .contents {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    z-index: 999;

    @include breakpoint("mobile") {
      padding: 9rem 1rem 2rem 1rem;
    }
  }

  .bg {
    position: absolute;
    height: 100%;
    width: 100%;

    .duck {
      bottom: 2rem;
      left: 22rem;
      width: 150px;
      z-index: 2;
      transition:
        width 1s ease,
        left 1s ease;

      @media (max-width: 1750px) {
        width: 130px;
        left: 15rem;
      }

      @media (max-width: 1300px) {
        width: 100px;
        left: 10rem;
      }

      @include breakpoint("mobile") {
        width: 40px;
        left: 2rem;
        bottom: 10px;
      }
    }

    .penguin {
      bottom: 0rem;
      width: 28rem;
      right: 8rem;
      z-index: 2;
      transition:
        right 1s ease,
        width 1s ease;

      @media (max-width: 1750px) {
        right: 3rem;
        width: 22rem;
      }

      @media (max-width: 1300px) {
        right: 1rem;
        width: 15rem;
      }

      @include breakpoint("mobile") {
        width: 7.5rem;
      }
    }
    .snowfront {
      bottom: -5px;
      z-index: 3;
      width: 100%;

      @media (max-width: 1300px) {
        width: 150%;
      }
    }

    .banner {
      position: relative;
      height: 45%;
      width: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("../../../public/assets/images/story/snow.png");
      border-bottom: black solid 0.5rem;
      overflow: clip;
    }

    .tile {
      height: 100%;
      background-image: url("../../../public/assets/images/story/tile.png");
    }
  }

  .chapters {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
}

.titleCard {
  display: flex;
  flex-direction: column;
  align-items: center;

  .titleCardTitle {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -0.25rem;
  }

  .titleCardContent {
    border-radius: 1rem;
    border: black solid 0.4rem;
    background-color: #504d5b;
    padding: 2rem;
    max-width: 20rem;
    text-align: center;

    color: white;
    font-weight: 300;
    font-family: montserrat;
  }
}

.chapterCard {
  height: 6rem;
  width: 30rem;
  display: flex;
  flex-direction: row;
  font-family: montserrat;

  background-color: #ffffff;
  border-radius: 1rem;
  border: black 0.4rem solid;

  @include breakpoint("mobile") {
    width: calc(100% - 0.4rem);
  }

  &.active {
    &:hover {
      transform: scale(1.05);
      cursor: pointer;
    }
  }

  .chapterCardImage {
    height: 100%;
    aspect-ratio: 1;
    margin-left: -0.4rem;
    margin-top: -0.4rem;

    img {
      max-height: calc(100% + 2 * 0.4rem);
    }
  }

  .chapterCardContent {
    width: 100%;
    padding: 0.4rem;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;

    .chapterCardTitle {
      font-size: 1.4rem;
      font-weight: 600;

      @include breakpoint("mobile") {
        font-size: 1rem;
      }
    }

    .chapterCardReleaseDate {
      position: absolute;
      bottom: 0.4rem;
      right: 0.4rem;

      @include breakpoint("mobile") {
        font-size: 0.75rem;
      }
    }
  }
}
