@import "../../index.scss";

.root {
  .scrollable {
    background: white;
    border-left: 0.5rem solid black;
    border-right: 0.5rem solid black;

    @include breakpoint("mobile") {
      padding-bottom: 2rem;
    }

    &.extraPadding {
      padding: 2rem;
      @include breakpoint("mobile") {
        padding: 1rem;
      }
    }

    .buttonWrapper {
      scale: 0.9;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .panel {
    font-family: montserrat;
    color: #ffffff;
    font-weight: 900;
    font-size: 4rem;
    text-align: center;

    img {
      width: 100%;
    }
  }

  .preview {
    padding: 4rem 4rem 8rem 4rem;

    .container {
      position: relative;
      z-index: 999;

      .main {
        background: #545454;
        position: relative;
        z-index: 9999;
        background: white;
        border: 0.5rem solid black;
      }

      .trail {
        background: white;
        border: 0.5rem solid black;
        left: 0;
        right: 0;
        position: absolute;
        opacity: calc((1 - 0.25 * var(--index)));
        scale: calc((1 - 0.05 * var(--index)));
        z-index: calc(9999 - var(--index));
        bottom: calc(-7.5% * (var(--index)));
      }
    }
  }
}
