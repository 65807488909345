@import "index.scss";

.modal {
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;

  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);

  &.center {
    justify-content: center;
  }

  &.transparentblur {
    backdrop-filter: blur(15px);
    background: none;
  }
  &.scrollable {
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .xClose {
    z-index: 999999;
    top: 2rem;
    right: 2rem;
    position: fixed;

    img {
      max-width: 1.75rem;
    }
  }

  .arrowClose {
    z-index: 9999;
    top: 17rem;
    left: 45rem;
    position: fixed;

    img {
      max-width: 5rem;
    }
  }

  .container {
    background: lightgrey;
    box-shadow: 0 0.5rem 0 0 rgba(0, 0, 0, 1);
    padding: 0 3rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 100vh;
    max-width: 700px;

    @include breakpoint("mobile") {
      max-width: 100vw;
      padding: 0px;
    }

    &.nobg {
      background: none;
      box-shadow: none;
    }
  }

  &.hide {
    display: none;
  }

  &.blur {
    background: none;
    backdrop-filter: blur(10px);
  }
}

.title {
  color: #000000;
  font-family: montserrat;
  font-weight: 900;
  font-size: 1.5rem;
  word-wrap: break-word;
  white-space: normal;
  text-transform: uppercase;
}

.body {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
