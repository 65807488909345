@import "../../../../index.scss";

.selectWrapper {
  position: relative;
  width: min-content;
}
.select {
  max-height: 300px;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 0.6rem;
  border: 0.4rem black solid;
  border-radius: 0.5rem;
  overflow-y: scroll;
  position: relative;
  width: min-content;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  @include breakpoint("mobile") {
    max-height: 200px;
  }
}
.grid {
  .gridContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0.4rem;
    grid-auto-flow: dense;

    @include breakpoint("desktop") {
      grid-template-columns: repeat(3, 1fr);
    }

    @include breakpoint("tablet") {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .cell {
    position: relative;
    width: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    color: white;

    &.woofy {
      background-color: #8ef8ff;
      aspect-ratio: 1 / 1.2;
    }

    &.empty {
      .cellContainer {
        background: none;
        img {
          filter: opacity(0.3);
        }
      }
      .capsule {
        filter: opacity(0.3);
      }
      .id {
        display: none;
      }
    }

    &.selectable {
      cursor: pointer;
      &:hover {
        .capsule {
          scale: 1.05;
          z-index: 9999;
        }
      }
    }

    &.selected {
      .capsule {
        rotate: -10deg;
        z-index: 9999;
        width: 58px;
      }
    }

    .capsule {
      position: relative;
      display: flex;
      justify-content: center;
      width: 55px;
      height: 110px;
      background-image: url("../../../../../public/assets/images/region/polarpeaks/campsite/capsule_2.png");
      background-size: cover;
      background-position: center;
      margin: 5px 0px;

      .capsuleId {
        position: absolute;
        bottom: 5%;
        font-family: montserrat;
        font-weight: 600;
        font-size: 1rem;
      }
    }

    .cellContainer {
      background: white;
      z-index: 999;
      position: relative;
      display: flex;
      border: 0.2rem black solid;

      img {
        height: 100%;
        width: 100%;
      }

      img {
        height: 100%;
        width: 100%;
      }
    }
    .id {
      bottom: 0;
      position: absolute;
      color: black;
      z-index: 9999;
      border: 0.2rem black solid;
      background-color: white;
      width: calc(90px - 2 * 3px);
      font-size: 0.9rem;
      font-family: montserrat;
      font-weight: 500;
      text-align: center;
    }
  }
}
