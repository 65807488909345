@import "../../index.scss";

.root {
  border: 0.4rem solid black;
  border-radius: 2rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 1rem;
  background-color: rgba(255, 255, 255, 0.6);

  @include breakpoint("mobile") {
    gap: 0.5rem;
  }

  @include breakpoint("mobile") {
    flex-direction: column;
    margin: 0 1rem;
  }

  .traits {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    min-width: 50%;

    @include breakpoint("mobile") {
      gap: 0.35rem;
    }
  }
}

.portraitCard {
  height: 100%;
  border: 0.4rem solid black;
  border-radius: 2rem;
  background-color: #454456;

  .portraitImage {
    border: 0.4rem solid black;
    border-radius: 2rem;
    margin: -0.4rem;
    overflow: clip;

    img {
      max-width: 100%;
      aspect-ratio: 1;
    }
  }

  &.packLeader {
    .portraitTraits {
      display: flex;
      flex-direction: column;
      align-items: center;

      .portraitTrait {
        .portraitTraitValue,
        .portraitTraitName {
          font-size: 1.5rem;
        }
      }
    }
  }

  .portraitInfo {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    font-family: montserrat;
    color: white;
    gap: 1rem;
    align-items: stretch;

    @include breakpoint("mobile") {
      padding: 0.5rem 2rem;
      gap: 0.25rem;
    }

    .portraitName {
      text-align: center;
      font-size: 1.75rem;
      font-weight: 600;
    }

    .portraitTrait {
      display: flex;
      flex-direction: row;
      gap: 0.2rem;

      .portraitTraitName {
        font-size: 1.1rem;
        font-weight: 600;
      }

      .portraitTraitValue {
        font-size: 1.1rem;
        font-weight: 500;
        font-style: italic;
      }

      .packLeaderTraitValue {
        color: #f9da5c;
        font-weight: 700;
      }
    }
  }
}

.trait {
  position: relative;
  background-color: #454456;
  border-radius: 2rem;
  font-family: montserrat;

  .traitContents {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.25rem;
    padding: 1rem 1.25rem;

    @include breakpoint("mobile") {
      padding: 0.5rem 1.25rem;
    }

    .traitType {
      font-size: 1.2rem;
      font-weight: 700;
      color: white;

      @include breakpoint("mobile") {
        font-size: 1rem;
        font-weight: 500;
      }
    }

    .traitValue {
      font-size: 1.1rem;
      font-weight: 500;
      color: var(--color);
      white-space: nowrap;

      @include breakpoint("mobile") {
        font-size: 0.8rem;
      }
    }
  }

  .traitIcon {
    --border: 0.4rem;

    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    right: var(--border);
    background-color: var(--color);
    border-radius: 50%;
    border: var(--border) solid black;
    align-self: stretch;
    height: calc((100% - 2 * var(--border)) * 0.75);
    aspect-ratio: 1;
  }
}
