@import "../../../index.scss";

.root {
  * {
    font-family: montserrat;
  }

  .title {
    @extend .textOutline;
    position: absolute;
    top: 0;
    font-size: 4rem;
    text-transform: uppercase;
    color: white;
    font-family: DonJoseBlack;
    line-height: 4rem;
    --stroke-width: 5px;
    padding-top: 4rem;
    text-align: center;
    @include breakpoint("tablet") {
      font-size: 2.5rem;
      --stroke-width: 3px;
    }
    @include breakpoint("mobile") {
      padding-top: 3rem;
      line-height: 2rem;
    }
  }

  .section1 {
    background-image: url("../../../../public/assets/images/collection/woofys/1/bg.png");
    background-position: center;
    height: 100vh;
    min-height: 900px;

    @include breakpoint("tablet") {
      min-height: 700px;
    }

    .content {
      display: flex;
      flex-direction: column;
      max-width: 40rem;
      align-items: center;
      gap: 2rem;
      z-index: 3;

      @include breakpoint("mobile") {
        gap: 1rem;
        padding-top: 5rem;
      }

      .logoWoofy {
        position: relative;
        width: 350px;
        @include breakpoint("mobile") {
          width: 250px;
        }
      }
      .subtitle {
        @extend .textOutline;
        font-size: 3rem;
        text-align: center;
        text-transform: uppercase;
        color: white;
        font-family: DonJoseBlack;
        line-height: 2rem;

        @include breakpoint("mobile") {
          font-size: 2rem;
        }
      }

      .buttonsWrapper {
        display: flex;
        gap: 1rem;
        padding-top: 2rem;
        @include breakpoint("mobile") {
          flex-direction: column;
        }
      }
    }

    .aceBig {
      width: 450px;
      bottom: -5px;
      z-index: 2;
      left: 0;
      transition: width 1s ease;

      @media (max-width: 1750px) {
        width: 400px;
      }
      @media (max-width: 1400px) {
        width: 300px;
        left: -4rem;
      }
      @include breakpoint("tablet") {
        width: 250px;
      }
      @include breakpoint("mobile") {
        width: 200px;
      }
    }
    .iceAxe {
      width: 500px;
      right: -3rem;
      bottom: -5px;
      z-index: 2;
      transition: width 1s ease;

      @media (max-width: 1750px) {
        width: 450px;
      }
      @media (max-width: 1400px) {
        width: 300px;
      }
      @include breakpoint("tablet") {
        width: 250px;
      }
      @include breakpoint("mobile") {
        width: 200px;
        bottom: -15px;
      }
    }
  }
  .section2 {
    background-image: url("../../../../public/assets/images/collection/woofys/1/bg_meet.png");
    background-position: center;
    height: 100vh;
    min-height: 750px;

    .content {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-right: 50%;
      gap: 1rem;
      z-index: 3;

      @include breakpoint("tablet") {
        padding-right: 0rem;
      }

      .textWrapper {
        display: flex;
        align-items: center;
        justify-self: center;
        background-color: white;
        width: 120%;
        grid-column: span 2;
        border: 4px solid black;
        border-radius: 30px;
        height: 400px;

        @include breakpoint("tablet") {
          width: 95%;
        }

        .text {
          padding: 2rem;
          font-size: 1.2rem;
          font-weight: 500;
          @include breakpoint("mobile") {
            padding: 1.5rem;
          }
        }
      }
    }

    .snowborder {
      width: 700px;
      right: 1rem;
      bottom: -5px;
      z-index: 2;
      transition: width 1s ease;

      @media (max-width: 1500px) {
        width: 550px;
      }
      @media (max-width: 1100px) {
        width: 400px;
        right: -2rem;
      }
      @include breakpoint("tablet") {
        width: 250px;
      }
    }
  }
  .section3 {
    background-image: url("../../../../public/assets/images/collection/woofys/1/bg2.png");
    background-position: center;
    height: 100vh;
    min-height: 750px;

    .content {
      display: flex;
      flex-direction: column;
      max-width: 40rem;
      align-items: center;
      gap: 1rem;
      z-index: 3;

      .leadersWrapper {
        display: grid;
        position: relative;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1.5fr 1fr;
        gap: 0.5rem;
        width: 100%;
        @include breakpoint("mobile") {
          grid-template-rows: 1fr 2fr;
        }

        .leaderImg {
          position: relative;
          justify-self: end;
          top: 30px;
          img {
            height: 320px;
            width: auto;
            @include breakpoint("tablet") {
              height: 270px;
            }
            @include breakpoint("mobile") {
              height: 180px;
            }
          }
        }
        .leadersGrid {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: repeat(4, 1fr);
          align-self: end;
          justify-self: start;
          gap: 0.2rem;
          .cell {
            display: flex;
            align-self: center;
            justify-self: center;
            width: min-content;
            height: auto;
            opacity: 0.5;
            cursor: pointer;
            img {
              width: 150px;
              @include breakpoint("tablet") {
                width: 120px;
              }
              @include breakpoint("mobile") {
                width: 80px;
              }
            }
            &.active {
              opacity: 1;
            }
            &:hover {
              opacity: 1;
            }
          }
        }
        .leaderAbout {
          display: flex;
          align-items: center;
          justify-self: center;
          background-color: white;
          width: 110%;
          grid-column: span 2;
          border: 4px solid black;
          border-radius: 5px;

          @include breakpoint("tablet") {
            width: 95%;
          }

          .text {
            padding: 2rem;
            font-size: 1.2rem;
            font-weight: 500;
            @include breakpoint("tablet") {
              padding: 2rem;
            }
            @include breakpoint("mobile") {
              padding: 1.5rem;
            }
          }
        }
      }
    }

    .capsule {
      width: 500px;
      bottom: 0;
      z-index: 2;
      left: 0;
      transition: width 1s ease;

      @media (max-width: 1750px) {
        width: 400px;
      }
      @include breakpoint("tablet") {
        width: 300px;
        left: 25%;
      }
      @include breakpoint("mobile") {
        width: 180px;
      }
    }
  }

  .section4 {
    background-image: url("../../../../public/assets/images/collection/woofys/1/bg_rescue.png");
    background-position: center;
    height: 100vh;
    min-height: 850px;

    .content {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
      z-index: 3;

      @include breakpoint("tablet") {
        gap: 1rem;
      }

      .panelsWrapper {
        display: grid;
        justify-content: center;
        gap: 3rem;
        grid-auto-flow: column;
        padding-right: 4rem;

        @include breakpoint("tablet") {
          grid-auto-flow: row;
          gap: 0rem;
          padding-right: 2rem;
        }

        .panel {
          display: grid;
          grid-auto-flow: row;

          .title {
            @extend .textOutline;
            position: relative;
            left: 15%;
            --stroke-width: 3px;
            font-size: 2rem;
            line-height: 1.5rem;
            text-align: center;
            text-transform: uppercase;
            color: #bae4f4;
            font-family: DonJoseBlack;
            padding: 0;

            @include breakpoint("tablet") {
              left: 10%;
              font-size: 1.5rem;
              line-height: 1.2rem;
            }
          }

          .text {
            width: 250px;
            height: 150px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            font-size: 1rem;
            line-height: 1.5rem;
            padding: 1rem 1rem 2rem 5rem;
            z-index: 0;
            color: white;
            text-align: center;
            font-weight: 500;

            @include breakpoint("tablet") {
              width: 200px;
              height: 100px;
              font-size: 0.9rem;
              line-height: 1.2rem;
              padding-left: 4rem;
            }
          }

          .text:before {
            position: absolute;
            box-sizing: border-box;
            content: "";
            width: 100%;
            left: 0;
            bottom: 0;
            z-index: -1;
            height: 100%;
            border: 4px solid black;
            border-radius: 5px;
            transform: skew(-15deg);
            transform-origin: right bottom;
            background-color: #4a5b77;
          }
          .buttons {
            display: grid;
            grid-auto-flow: column;
            justify-content: center;
            gap: 1rem;

            .squareButton {
              @extend .textOutline;
              --stroke-color: black;
              --stroke-width: 3px;
              font-family: DonJoseBlack;
              font-size: 1.3rem;
              text-transform: uppercase;
              line-height: 1;
              background-color: var(--bg-color);
              cursor: pointer;
              position: relative;
              padding: 1rem 1.5rem;
              border: 4px solid black;
              border-radius: 1rem;
              color: white;
              user-select: none;
              top: -50%;

              a {
                padding: 0;
                color: inherit;
              }

              &:hover {
                background-color: #ffffff;
                color: var(--bg-color);
              }

              &:active:hover {
                position: relative;
                top: -40%;
              }

              @include breakpoint("mobile") {
                font-size: 1.2rem;
                padding: 1rem 1.3rem;
                --stroke-width: 3px;
              }
            }
          }
        }
      }
    }
    .pebbles {
      width: 700px;
      bottom: 0;
      z-index: 2;
      right: -2rem;
      transition: width 1s ease;

      @media (max-width: 1750px) {
        width: 500px;
      }
      @include breakpoint("tablet") {
        width: 300px;
      }
      @include breakpoint("mobile") {
        width: 250px;
      }
    }
  }

  .section5 {
    background-image: url("../../../../public/assets/images/collection/woofys/1/bg_faq.png");
    background-position: center;
    min-height: 100vh;

    .content {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 3rem;
      z-index: 3;
      padding: 10rem 0rem;
      @include breakpoint("mobile") {
        padding: 6rem 0rem;
      }
    }
    .questionsWrapper {
      position: relative;
      display: grid;
      gap: 0.5rem;
      width: 100%;

      .question {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 580px;

        @include breakpoint("mobile") {
          max-width: 340px;
        }
        .title {
          @extend .textOutline;
          position: relative;
          --stroke-color: black;
          --stroke-width: 3px;
          box-sizing: border-box;
          width: 100%;
          color: white;
          background-color: #5aabfe;
          border: 4px solid black;
          border-radius: 20px;
          font-family: DonJoseBlack;
          font-size: 1.3rem;
          text-transform: uppercase;
          line-height: 1;
          padding: 1rem 1.5rem;
          cursor: pointer;
          transition: transform 0.1s;
          z-index: 1;

          &:hover {
            transform: scale(102%);
          }
          @include breakpoint("mobile") {
            font-size: 1.2rem;
          }
        }
        .answer {
          position: relative;
          top: -5%;
          box-sizing: border-box;
          background-color: #4a5b77;
          width: 95%;
          height: min-content;
          font-size: 0.9rem;
          line-height: 1.5rem;
          padding: 1rem;
          z-index: 0;
          color: white;
          border: 4px solid black;
          border-radius: 5px;
          text-align: center;
          overflow-wrap: break-word;
          opacity: 1;
          max-height: 500px;
          font-weight: 500;
          transition:
            opacity 0.5s,
            max-height 0.5s,
            padding 0.1s;

          &.hide {
            transition:
              opacity 0.1s,
              max-height 0.1s,
              padding 0.1s;
            opacity: 0;
            max-height: 0;
            padding: 0;
            border: 0;
          }
          .link {
            @extend .textOutline;
            --stroke-color: black;
            --stroke-width: 3px;
            font-family: DonJoseBlack;
            text-decoration: none;
            position: relative;
            font-size: 1rem;
            padding: 0rem 0.5rem;
            color: #42e6e8;

            @include breakpoint("mobile") {
              font-size: 0.9rem;
              --stroke-width: 2px;
            }
          }
        }
      }
    }
  }

  .sign {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .panel {
      position: relative;
      background-image: url("../../../../public/assets/images/collection/woofys/1/panel_thick.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      height: 100px;
      width: 550px;
      @include breakpoint("mobile") {
        background-image: url("../../../../public/assets/images/collection/woofys/1/panel_slim.png");
        height: 90px;
        width: 200px;
      }
    }

    .text {
      position: absolute;
      color: #ffffff;
      font-weight: 600;
      font-size: 1.2rem;
      text-align: center;
      padding-left: 1.5rem;
      padding-right: 2rem;
      padding-bottom: 0.3rem;
      @include breakpoint("mobile") {
        font-size: 0.75rem !important;
      }
    }
    &.small {
      .panel {
        width: 400px;
        height: 70px;
        @include breakpoint("mobile") {
          height: 90px;
          width: 200px;
        }
      }
      .text {
        font-size: 0.9rem;
      }
    }
  }

  .snowfront {
    bottom: -5px;
    z-index: 3;
    width: 100%;

    @media (max-width: 1300px) {
      width: 150%;
    }
  }

  .snowbehind {
    bottom: 0;
    z-index: 0;
    width: 100%;

    @media (max-width: 1300px) {
      width: 150%;
      bottom: -5px;
    }
    &.hide {
      bottom: 30px;
      @media (max-width: 1400px) {
        display: none;
      }
    }
  }
}
