@import "index.scss";

.cardWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: top 1s ease;
  top: 12rem;

  @media (max-width: 1800px) {
    top: 18rem;
  }

  @include breakpoint("mobile") {
    margin-right: 2rem;
    margin-left: 2rem;
    top: 0rem;
  }

  .card {
    width: 85%;
    background-color: white;
    border-radius: 1rem;
    border-color: black;
    border-style: solid;
    border-width: 0.5rem;
    box-shadow: 0 0.4rem 0 0 rgb(0, 0, 0, 1);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    @include breakpoint("mobile") {
      width: 95%;
    }

    .banner {
      width: 100%;

      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }

    .content {
      position: relative;
      padding: 2rem;
      height: 14rem;
      color: black;
      font-size: 1.6rem;
      font-family: montserrat;
      font-weight: 500;
      text-align: center;
      flex-direction: column;
      display: flex;
      justify-content: center;

      @include breakpoint("mobile") {
        text-align: center;
        padding-top: 1rem;
        padding-bottom: 2rem;
        font-size: 1.5rem;
        height: 9rem;
      }
      .redBold {
        color: #d0342c;
        font-weight: bold;
      }
    }
  }

  .button {
    margin-top: -4rem;
    margin-bottom: 1rem;
    z-index: 1;

    @include breakpoint("mobile") {
      margin-top: -3rem;
      width: 90%;
    }
  }

  .appStoreDesktopButtons {
    display: grid;
    margin-top: 1rem;
    column-gap: 1rem;
    grid-template-columns: 1fr 1fr;
  }

  .logo {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    width: 250px;
    height: auto;

    @include breakpoint("mobile") {
      width: 160px;
      margin-top: 0rem;
    }
  }

  .learnMore {
    margin-top: -4rem;
    z-index: 1;
  }
}
.mobileNotSupportedWrapper {
  display: none;
  @include breakpoint("mobile") {
    display: flex;
    justify-content: center;
    width: 100vh;
    z-index: 10;
  }
}
